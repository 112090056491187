import { mapState, mapGetters } from 'vuex';

function createNav(profile, scope, me, roles) {
    const navs = [{
        name: 'Мои задачи',
        route: { name: 'tasks' },
        items: [],
    }];

    if (!me) {
        return navs;
    }

    if (profile && profile.startForms > 0) {
        navs.push({
            name: 'Мои процессы',
            route: { name: 'processes' },
            items: [],
        });
    }

    if (!scope || !scope.path) {
        return navs;
    }

    navs.push({ name: null });

    navs.push(...scope.path.map(i => ({
        name: i.name,
        route: { name: 'app-card', params: { appID: i.id } },
        items: [],
    })));

    if (!scope.id) {
        return navs;
    }

    const appID = scope.id;
    const items = [];

    items.push({
        name: 'Задачи',
        route: { name: 'app-tasks', params: { appID } },
    });
    items.push({
        name: 'Реестры',
        route: { name: 'app-registries', params: { appID } },
    });

    if (roles.isUsersManager) {
        items.push({
            name: 'Пользователи',
            route: { name: 'app-users', params: { appID } },
        });
    }
    if (roles.isProcessesManager) {
        items.push({
            name: 'Процессы',
            route: { name: 'app-processes', params: { appID } },
        });
    }
    if (roles.isSchemasManager) {
        items.push({
            name: 'Схемы',
            route: { name: 'app-schemas', params: { appID } },
        });
    }
    if (roles.isTypesManager) {
        items.push({
            name: 'Типы',
            route: { name: 'app-types', params: { appID } },
        });
    }
    if (roles.isScopesManager) {
        items.push({
            name: 'Настройка',
            route: { name: 'app-config', params: { appID } },
        });
    }

    navs.push({
        name: scope.name,
        route: { name: 'app-card', params: { appID } },
        items,
    });

    return navs;
}

export default {
    name: 'NavPanel',

    data() {
        return {
            navs: [],
        };
    },

    computed: {
        ...mapState('profile', ['profile', 'scope']),
        ...mapGetters('profile', ['myRoles', 'scopeRoles']),
    },

    watch: {
        scope() { this.update(); },
    },

    created() {
        this.update();
    },

    methods: {
        update() {
            this.navs = createNav(this.profile, this.scope, this.myRoles, this.scopeRoles);
        },
    },
};
