import _get from 'lodash/get';
import Api from '@/api';
import PagedList from '@/store/pagedlist';

import * as A from './actions';
import * as M from './mutations';

const page = new PagedList('pages');

const initState = {
    appID: null,
    list: [],
    error: null,
    pages: page.initState(),
    filters: {},
    available: {},
};

const getters = { };

const mutations = {
    [M.LOAD](state, {
        appID, list, pagination, filters, available,
    }) {
        state.appID = appID;
        state.list = list;
        state.pages = page.readState(pagination);
        state.error = null;
        state.filters = filters;
        state.available = available;
    },
    [M.RESET](state) {
        state.appID = null;
        state.list = [];
        state.pages = page.initState();
        state.error = null;
        state.filters = {};
        state.available = {};
    },
    [M.ERROR](state, error) {
        state.error = error;
    },
};

const actions = {
    ...page.mapActions(),

    [A.UPDATE_LIST]({ dispatch, state }, { appID }) {
        return (page.obsolete(state) || appID !== state.appID)
            ? dispatch(A.LOAD_LIST, { appID }) : Promise.resolve();
    },

    [A.LOAD_LIST]({ commit, dispatch, state }, opts) {
        const appID = _get(opts, 'appID', state.appID);
        const pageOpts = page.pageOpts(state, opts);

        return new Promise((resolve, reject) => {
            Api.scopeRegistries(appID, pageOpts).then((resp) => {
                commit(M.LOAD, { appID, ...resp.data });
                dispatch('profile/UPDATE_NAV', { appID }, { root: true });
                resolve(resp.data);
            }).catch((err) => {
                commit(M.ERROR, err);
                reject(err);
            });
        });
    },

    [A.FILTER_SEARCH]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'search', value });
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
