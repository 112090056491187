<template>
    <div>
        <app-loading v-if="isLoading" />
        <app-not-found v-else>
            <h2>Нет доступа, обратитесь к системному админитратору</h2>
        </app-not-found>
    </div>
</template>
<script>

import AppLoading from '@/components/common/AppLoading';
import AppNotFound from '@/components/common/AppNotFound';

export default {
    name: 'AppDefault',
    components: { AppLoading, AppNotFound },

    data() {
        return {
            isLoading: true,
        };
    },

    mounted() {
        this.$router.replace({ name: 'tasks' });
    },
};

</script>
