// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import isDate from 'lodash/isDate';
import isNumber from 'lodash/isNumber';
import fecha from 'fecha';

import Vue from 'vue';
import App from '@/App';
import Store from '@/store';
import Router from '@/router';

Vue.config.productionTip = false;

const i18ru1 = {
    monthNames: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
};

function formatDate(d) {
    return fecha.format(d, 'DD MMMM YYYY HH:mm', i18ru1);
}

// Фильтр простого форматирования
// eslint-disable-next-line no-control-regex
const simpleHtml = new RegExp('\r?\n', 'g');
Vue.filter('toHTML', txt => txt.replace(simpleHtml, '<br />'));

// Фильтр для отображения дат
Vue.filter('formatDate', (value) => {
    if (isDate(value)) {
        return formatDate(value);
    }
    if (isNumber(value)) {
        const unixtime = value > 16000000000 ? value : value * 1000;
        return formatDate(new Date(unixtime));
    }
    return '';
});

// Фильтр для отображения размеров файлов
Vue.filter('fileSize', (value) => {
    if (!value) {
        return 'none';
    }
    let suffix = 'b';
    let size = value;
    if (size > 1024) {
        suffix = 'Kb';
        size /= 1024;
    }
    if (size > 1024) {
        suffix = 'Mb';
        size /= 1024;
    }
    if (size > 1024) {
        suffix = 'Gb';
        size /= 1024;
    }

    const rounded = Math.round(size * 10) / 10;
    return rounded + suffix;
});

// Фильтр для отображения имен и аватора пользователей
Vue.filter('userFullName', (user) => {
    if (!user || !user.username) {
        return 'none';
    }
    const name = [user.firstName, user.middleName, user.lastName].filter(s => s != null).join(' ');
    return name.length > 2 ? name : user.username;
});
Vue.filter('myAvatar', (user, touch) => ((user && user.has_avatar) ? `/api/profile/avatar.png?t=${touch}` : '/avatar.png'));
Vue.filter('userAvatar', (user, touch) => ((user && user.id && user.has_avatar) ? `/api/avatar/u/${user.id}.png?t=${touch}` : '/avatar.png'));
Vue.filter('assignedAvatar', (user, sid, touch) => ((user && user.id && user.has_avatar) ? `/api/avatar/a/${sid}/${user.id}.png?t=${touch}` : '/avatar.png'));
Vue.filter('processAvatar', (user, pid, touch) => ((user && user.id && user.has_avatar) ? `/api/avatar/p/${pid}/${user.id}.png?t=${touch}` : '/avatar.png'));

/* eslint-disable no-new */
new Vue({
    el: '#app',
    store: Store,
    router: Router,
    components: { App },
    template: '<App/>',
});
