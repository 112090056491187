<template>
    <div class="form-group">
        <label class="form-label">Аватар</label>
        <div class="ava">
            <img :src="avatar" class="float-left"/>
            <div>
                <div class="btn btn-primary upload" v-if="avatarData == null && disabled == false">
                    Сменить
                    <input type="file" accept="image/*"
                        @change="change($event.target.files[0])" />
                </div>
                <button class="btn btn-primary" v-if="avatarData != null"
                    @click="reset()">Отменить</button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.ava {
    img {
        width: 64px;
        height: 64px;
    }
    .btn {
        margin-left: 30px;
        margin-top: 10px;
    }
    .upload {
        position: relative;
    }
    .upload [type=file] {
        cursor: inherit;
        display: block;
        filter: alpha(opacity=0);
        height: 100%;
        width: 100%;
        opacity: 0;
        position: absolute;
        right: 0;
        text-align: right;
        top: 0;
    }
}

</style>

<script>
export default {
    name: 'UserAvatarForm',
    props: ['avatarURL', 'disabled'],
    data() {
        return {
            avatarPreview: null,
            avatarData: null,
        };
    },
    computed: {
        avatar() {
            return this.avatarPreview != null ? this.avatarPreview : this.avatarURL;
        },
    },
    methods: {
        change(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.avatarPreview = e.target.result;
            };
            reader.readAsDataURL(file);
            this.avatarData = file;
            this.$emit('avatar-changed', true);
        },

        reset() {
            this.avatarData = null;
            this.avatarPreview = null;
            this.$emit('avatar-changed', false);
        },
    },
};

</script>
