<template>
    <button class="btn btn-primary" :class="{ 'loading': isLoading }"
        :disabled="disabled" @click="click()">
        <slot></slot>
    </button>
</template>

<script>

const MIN_TIME = 500;

export default {
    name: 'UploadButton',

    props: {
        enabled: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isLoading: false,
            from: null,
        };
    },

    computed: {
        disabled() { return !this.enabled && !this.isLoading; },
    },

    methods: {
        stop() {
            if (!this.isLoading) {
                return;
            }
            const diff = new Date().getTime() - this.from;
            if (diff > MIN_TIME) {
                this.isLoading = false;
            } else {
                setTimeout(() => { this.isLoading = false; }, (MIN_TIME - diff) + 100);
            }
        },

        click() {
            this.isLoading = true;
            this.from = new Date().getTime();
            this.$emit('upload-click');
        },
    },
};

</script>
