<template>
    <div>
        <app-loading v-if="isLoading" />
    </div>
</template>
<script>

import { mapActions } from 'vuex';
import { COMPLETE_AUTH } from '@/store/actions';

import AppLoading from '@/components/common/AppLoading';

export default {
    name: 'AppCompleteAuth',
    components: { AppLoading },

    data() {
        return {
            isLoading: true,
        };
    },

    methods: {
        ...mapActions({
            completeAuth: COMPLETE_AUTH,
        }),
    },

    mounted() {
        const payload = {
            auth: this.$route.query.state,
            code: this.$route.query.code,
        };
        this.completeAuth(payload).finally(() => {
            this.$router.replace({ name: 'default' });
        });
    },
};

</script>
