<template>
    <div>
        <app-loading v-if="!isNotLogged && !isLogged"></app-loading>

        <app-login v-if="isNotLogged"></app-login>

        <div v-if="isLogged" class="container">
            <div class="columns">
                <div class="column col-3 col-md-4 col-sm-5">
                    <navPanel />
                </div>
                <div class="column col-8 col-md-7 col-sm-7">
                    <router-view />
                </div>
            </div>
            <msg-list />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.column {
    padding: 5px;
}
</style>

<script>

import { mapGetters, mapActions } from 'vuex';
import { INIT } from '@/store/actions';

import AppLoading from '@/components/common/AppLoading';

import NavPanel from './NavPanel';
import AppLogin from './AppLogin';
import MsgList from './MsgList';

export default {
    name: 'AppBody',

    components: {
        NavPanel, AppLoading, AppLogin, MsgList,
    },

    computed: {
        ...mapGetters(['isLogged', 'isNotLogged']),
    },

    methods: {
        ...mapActions({
            initApp: INIT,
        }),
    },

    mounted() {
        this.initApp();
    },
};

</script>
