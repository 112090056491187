<template>
    <div class="text-right" v-if="total > 10">
        <div class="btn-group">
            <button class="btn btn-link"
                :class="{active: size==10}" @click="changeSize(10)">10</button>
            <button class="btn btn-link"
                :class="{active: size==20}" @click="changeSize(20)">20</button>
            <button class="btn btn-link"
                :class="{active: size==50}" @click="changeSize(50)">50</button>
        </div>
        <slot />
    </div>
</template>

<style lang="scss" scoped>
.btn {
    color: gray;
}
</style>


<script>
export default {
    name: 'TablePageSize',
    props: ['size', 'total'],

    methods: {
        changeSize(size) {
            if (size !== this.size) {
                this.$emit('changed', size);
            }
        },
    },
};
</script>
