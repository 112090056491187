import _get from 'lodash/get';
import Api from '@/api';
import PagedList from '@/store/pagedlist';

import * as A from './actions';
import * as M from './mutations';

const page = new PagedList('pages');

const initState = {
    appID: null,
    list: [],
    pages: page.initState(),
    selected: null,
    error: null,
};

const getters = { };

const mutations = {
    [M.LOAD_LIST](state, {
        appID, list, pagination, filters,
    }) {
        state.appID = appID;
        state.list = list;
        state.pages = page.readState(pagination, filters);
        // state.selected = null;
        state.error = null;
    },
    [M.SELECT_SCHEMA](state, selected) {
        state.selected = selected;
        state.error = null;
    },
    [M.ERROR](state, error) {
        state.error = error;
    },
    [M.RESET](state) {
        state.appID = null;
        state.list = [];
        state.pages = page.initState();
        state.selected = null;
        state.error = null;
    },
};

const actions = {
    ...page.mapActions(),

    [A.UPDATE_LIST]({ dispatch, state }, { appID }) {
        return (page.obsolete(state) || appID !== state.appID)
            ? dispatch(A.LOAD_LIST, { appID }) : Promise.resolve();
    },

    [A.LOAD_LIST]({ commit, dispatch, state }, opts) {
        const appID = _get(opts, 'appID', state.appID);
        const pageOpts = page.pageOpts(state, opts);

        return new Promise((resolve, reject) => {
            Api.scopeSchemas(appID, pageOpts).then((resp) => {
                dispatch('profile/UPDATE_NAV', { appID }, { root: true });
                commit(M.LOAD_LIST, { appID, ...resp.data, filters: pageOpts.filters });
                resolve(resp.data);
            }).catch((err) => {
                commit(M.RESET);
                reject(err);
            });
        });
    },

    [A.UPLOAD_SCHEMA]({ dispatch, state }, payload) {
        const data = new FormData();
        data.append('file', payload);

        return new Promise((resolve, reject) => {
            Api.uploadProcessSchema(state.appID, data).then(() => {
                dispatch(A.LOAD_LIST).then(resolve, reject);
            }).catch(reject);
        });
    },

    [A.UPDATE_SCHEMA]({ commit }, payload) {
        const { id, ref } = payload;
        return new Promise((resolve, reject) => {
            Api.updateProcessSchema(id, ref).then((resp) => {
                commit(M.SELECT_SCHEMA, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.REMOVE_SCHEMA]({ dispatch }, schemaID) {
        return new Promise((resolve, reject) => {
            Api.removeProcessSchema(schemaID).then(() => {
                dispatch(A.LOAD_LIST).then(resolve, reject);
            }).catch(reject);
        });
    },
    [A.SELECT_SCHEMA]({ commit }, schemaID) {
        return new Promise((resolve, reject) => {
            Api.processSchema(schemaID).then((resp) => {
                commit(M.SELECT_SCHEMA, resp.data);
                resolve();
            }).catch(reject);
        });
    },
    [A.CLONE_PROCESSES]({ commit }, { schemaID, sourceID }) {
        return new Promise((resolve, reject) => {
            Api.cloneSchemaProcesses(schemaID, sourceID).then((resp) => {
                commit(M.ERROR, resp.data);
                resolve();
            }).catch((err) => {
                commit(M.ERROR, err);
                reject();
            });
        });
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
