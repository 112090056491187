import Api from '@/api';
import PagedList from '@/store/pagedlist';

import * as A from './actions';
import * as M from './mutations';

const page = new PagedList('pages');

const initState = {
    list: [],
    pages: page.initState(),
    filters: {},
    available: {},
    selected: null,
};

const getters = { };

const mutations = {
    [M.LOAD_LIST](state, {
        list, pagination, filters, available,
    }) {
        state.list = list;
        state.pages = page.readState(pagination);
        state.filters = filters;
        state.available = available;
        state.selected = null;
    },
    [M.SELECT_REPORT](state, selected) {
        state.selected = selected;
    },
    [M.RESET](state) {
        state.list = [];
        state.pages = page.initState();
        state.filters = {};
        state.available = {};
        state.selected = null;
    },
};

const actions = {
    ...page.mapActions(),

    [A.UPDATE_LIST]({ dispatch, state }) {
        return page.obsolete(state) ? dispatch(A.LOAD_LIST) : Promise.resolve();
    },

    [A.LOAD_LIST]({ commit, state }, opts) {
        const pageOpts = page.pageOpts(state, opts);
        return new Promise((resolve, reject) => {
            Api.listReports(pageOpts).then((resp) => {
                commit(M.LOAD_LIST, resp.data);
                resolve(resp.data);
            }).catch((err) => {
                commit(M.RESET);
                reject(err);
            });
        });
    },

    [A.FILTER_FINISHED]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'all', value });
    },

    [A.FILTER_SCHEMA]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'schema', value });
    },

    [A.FILTER_STATE]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'state', value });
    },

    [A.SELECT_REPORT]({ commit, state }, reportID) {
        return new Promise((resolve, reject) => {
            if (state.selected != null && state.selected.id === reportID) {
                resolve();
                return;
            }

            Api.reportInfo(reportID).then((resp) => {
                commit(M.SELECT_REPORT, resp.data);
                resolve();
            }).catch(reject);
        });
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
