<template>
    <div>
        <label class="form-label">
            <span>{{value.name}}</span>
        </label>
        <div v-if="value.type == 'string'">
            <input class="form-input" type="text" disabled :value="value.value" />
        </div>
        <div v-else-if="value.type == 'number'">
            <input class="form-input" type="text" disabled :value="value.value" />
        </div>
        <div v-else-if="value.type == 'text'">
            <div class="panel p-2" :inner-html.prop="value.value | toHTML" />
        </div>
        <div v-else-if="value.type == 'label'">
            <p>{{value.options}}</p>
        </div>
        <div v-else-if="value.type == 'enum'">
            <select class="form-select" v-model="value.value" disabled>
                <option :value="null">-- Не выбрано --</option>
                <option
                    v-for="option in list"
                    :key="option.id"
                    :value="option.id"
                >{{option.name}}</option>
            </select>
        </div>
        <div v-else-if="value.type == 'media'">
            <div :key="file.id" v-for="file in value.files">
                <file-info :file="file">
                    <a class="btn btn-primary tooltip tooltip-bottom" data-tooltip="Скачать"
                        target="_blank" rel="noopener noreferrer"
                        :href="`/api/files/t/${taskID}/${file.id}/content`">
                        <i class="icon icon-download"></i>
                    </a>
                </file-info>
            </div>
        </div>
        <div v-else class="has-error">
            <div class="form-input-hint">Данный тип аттрибута не поддерживается</div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.form-input:disabled {
    opacity: 1;
    background-color: white;
}
.form-select:disabled {
    opacity: 1;
    background-color: white;
}
</style>

<script>
import FileInfo from '@/components/common/FileInfo';

export default {
    name: 'TaskValue',
    props: { taskID: Number, value: Object },
    components: { FileInfo },
    computed: {
        list() {
            return this.value.type === 'enum' ? JSON.parse(this.value.options).options : [];
        },
    },
};
</script>
