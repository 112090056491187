import { IMaskComponent } from 'vue-imask';

function fromAttr(attr) {
    const item = {
        id: attr.id,
        attrID: attr.attr,
        name: attr.name,
        type: attr.type,
        is_required: attr.is_required,
        value: attr.value,
        show_required: false,
    };
    if (attr.type === 'enum') {
        item.list = JSON.parse(attr.options);
    }
    if (attr.type === 'masked') {
        item.mask = attr.options;
        item.is_completed = true;
    }
    return item;
}

function fromType(type) {
    const item = {
        attrID: type.id,
        name: type.name,
        type: type.type,
        is_required: type.is_required,
        value: null,
        show_required: false,
    };
    if (type.type === 'enum') {
        item.list = JSON.parse(type.options);
    }
    if (type.type === 'masked') {
        item.mask = type.options;
        item.is_completed = false;
    }
    return item;
}

function hasValue(attr) {
    if ((attr.type === 'text' || attr.type === 'string') && (attr.value == null || attr.value.length === 0)) {
        return false;
    }
    if (attr.type === 'enum' && attr.value == null) {
        return false;
    }
    if (attr.type === 'masked' && !attr.is_completed) {
        return false;
    }
    return true;
}

function filterExists(type, attrs) {
    if (!type) {
        return [];
    }
    return type.attrs.filter((a) => {
        for (let i = 0; i < attrs.length; i += 1) {
            if (attrs[i].attrID === a.id) {
                return false;
            }
        }
        return true;
    });
}

function sortedAttrs(attrs, type) {
    const orders = type.attrs.reduce((obj, attr) => {
        // eslint-disable-next-line no-param-reassign
        obj[attr.id] = attr.order;
        return obj;
    }, {});

    return attrs.sort((a1, a2) => orders[a1.attrID] - orders[a2.attrID]);
}

export default {
    name: 'AppAttrList',
    props: {
        attrs: Array,
        type: Object,
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        ImaskInput: IMaskComponent,
    },

    data() {
        const attrsList = this.attrs ? this.attrs.map(fromAttr) : this.type.attrs.map(fromType);
        const extended = filterExists(this.type, attrsList);
        const editable = Boolean(this.attrs != null && !this.disabled);

        return {
            attrsList,
            extended,
            editable,
        };
    },

    watch: {
        type() { this.reload(); },
    },

    methods: {
        validate() {
            let ok = true;
            for (let i = 0; i < this.attrsList.length; i += 1) {
                const attr = this.attrsList[i];
                attr.show_required = attr.is_required && !hasValue(attr);
                ok = ok && !attr.show_required;
            }
            // Я так и не понял почему Vue видит изменение show_required только в первый раз
            // поэтому здесь вызывают обновление в ручную
            this.$forceUpdate();
            return ok;
        },

        payload() {
            return this.attrsList.filter(hasValue).map(a => ({
                id: a.id,
                attr: a.attrID,
                value: a.value,
            }));
        },

        notify() {
            if (!this.attrs) {
                return;
            }
            let changed = false;
            if (this.attrsList.length !== this.attrs.length) {
                changed = true;
            } else {
                for (let i = 0; i < this.attrsList.length; i += 1) {
                    const a = this.attrsList[i];
                    const b = this.attrs[i];
                    if (a.id !== b.id || String(a.value) !== String(b.value)) {
                        changed = true;
                    }
                }
            }
            this.$emit('changed', changed);
        },

        reload() {
            this.attrsList = this.attrs ? this.attrs.map(fromAttr) : this.type.attrs.map(fromType);
            this.extended = filterExists(this.type, this.attrsList);
            this.notify();
        },

        addAttribute(attr) {
            this.attrsList.push(fromType(attr));
            this.attrsList = sortedAttrs(this.attrsList, this.type);
            this.extended = filterExists(this.type, this.attrsList);
            this.notify();
        },

        removeAttribute(idx) {
            this.attrsList.splice(idx, 1);
            this.attrsList = sortedAttrs(this.attrsList, this.type);
            this.extended = filterExists(this.type, this.attrsList);
            this.notify();
        },
    },
};
