import _get from 'lodash/get';

import * as A from './actions';

const OBSOLETE_TIMEOUT = 60 * 1000; // 60 Секунд для таймаута обновления

function PagedList(key) {
    this.initState = () => ({
        page: 0,
        size: 10,
        count: 0,
        total: 0,
        lastTouch: 0,
    });

    this.readState = pagination => ({
        page: Math.floor(pagination.offset / pagination.pagesize),
        size: pagination.pagesize,
        count: Math.ceil(pagination.total / pagination.pagesize) - 1,
        total: pagination.total,
        lastTouch: new Date().getTime(),
    });

    this.pageOpts = (state, opts) => {
        const size = _get(opts, 'size', state[key].size);
        const offset = _get(opts, 'page', state[key].page) * size;
        const filters = _get(opts, 'filters', state.filters);
        return { size, offset, filters };
    };

    this.obsolete = state => new Date().getTime() - state[key].lastTouch > OBSOLETE_TIMEOUT;

    // Небольшая обертка для случая, когда в результатет запроса получен
    // пустой список - сбрасываем на первую страницу
    function reload(dispatch, opts) {
        return new Promise((resolve, reject) => {
            dispatch(A.LOAD_LIST, opts).then((data) => {
                if (data
                    && (!data.list || !data.list.length)
                    && data.pagination
                    && data.pagination.total > 0) {
                    const firstPage = Object.assign(opts, { page: 0 });
                    dispatch(A.LOAD_LIST, firstPage).then(resolve, reject);
                } else {
                    resolve();
                }
            }).catch(reject);
        });
    }

    this.mapActions = () => ({
        [A.CHANGE_PAGE]({ dispatch }, page) {
            return reload(dispatch, { page: page || 0 });
        },
        [A.CHANGE_SIZE]({ dispatch, state }, nsize) {
            const size = nsize || 10;
            const page = Math.floor(state[key].page * state[key].size / size);
            return reload(dispatch, { page, size });
        },
        [A.SET_FILTER]({ dispatch, state }, { filter, value, reset }) {
            const filters = { ...state.filters };
            filters[filter] = value;
            if (reset && reset.length) {
                for (let idx = 0; idx <= reset.length; idx += 1) {
                    delete filters[reset[idx]];
                }
            }
            return reload(dispatch, { filters });
        },
        [A.CLEAR_FILTER]({ dispatch, state }, { filter, reset }) {
            const filters = { ...state.filters };
            delete filters[filter];
            if (reset && reset.length) {
                for (let idx = 0; idx <= reset.length; idx += 1) {
                    delete filters[reset[idx]];
                }
            }
            return reload(dispatch, { filters });
        },
    });
}

export default PagedList;
