<template>
    <div class="tile tile-centered">
        <slot name="prefix" />
        <div class="tile-icon">
            <div class="app-tile-icon">
                <i class="icon icon-bookmark centered"></i>
            </div>
        </div>
        <div class="tile-content">
            <div class="tile-title">{{file.filename}}</div>
            <small class="tile-subtitle text-gray">
                {{file.filesize | fileSize}} · Загружен
                {{file.uploader | userFullName}} {{file.uploaded | formatDate}}
            </small>
        </div>
        <slot />
    </div>
</template>

<style lang="scss" scoped>
.tile-action {
    margin-left: 2px;
}
</style>


<script>
export default {
    name: 'FileInfo',
    props: ['file'],
};
</script>
