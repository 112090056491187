import _get from 'lodash/get';
import Api from '@/api';
import PagedList from '@/store/pagedlist';

import * as A from './actions';
import * as M from './mutations';

const page = new PagedList('pages');

const initState = {
    typeID: null,
    list: [],
    error: null,
    pages: page.initState(),
    filters: {},
    available: {},
    selected: null,
};

const getters = { };

const mutations = {
    [M.LOAD_LIST](state, {
        typeID, list, pagination, filters, available,
    }) {
        state.typeID = typeID;

        state.list = list;
        state.pages = page.readState(pagination);
        state.error = null;
        state.filters = filters;
        state.available = available;
    },
    [M.RESET](state) {
        state.typeID = null;
        state.list = [];
        state.pages = page.initState();
        state.error = null;
        state.filters = {};
        state.available = {};
    },
    [M.ERROR](state, error) {
        state.error = error;
        state.list = [];
        state.selected = null;
    },

    [M.SELECT_REGISTRY](state, selected) {
        state.selected = selected;
    },
};

const actions = {
    ...page.mapActions(),

    [A.UPDATE_LIST]({ dispatch, state }, { typeID }) {
        return (page.obsolete(state) || typeID !== state.typeID)
            ? dispatch(A.LOAD_LIST, { typeID }) : Promise.resolve();
    },

    [A.LOAD_LIST]({ commit, state }, opts) {
        const typeID = _get(opts, 'typeID', state.typeID);
        const pageOpts = page.pageOpts(state, opts);

        return new Promise((resolve, reject) => {
            Api.listTypeRegistries(typeID, pageOpts).then((resp) => {
                commit(M.LOAD_LIST, { typeID, ...resp.data });
                resolve(resp.data);
            }).catch((err) => {
                commit(M.ERROR, err);
                reject(err);
            });
        });
    },

    [A.FILTER_SEARCH]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'search', value });
    },

    [A.SELECT_REGISTRY]({ commit }, { typeID, registryID }) {
        return new Promise((resolve, reject) => {
            Api.fetchTypeRegistry(typeID, registryID).then((resp) => {
                commit(M.SELECT_REGISTRY, resp.data);
                resolve();
            }).catch((err) => {
                commit(M.ERROR, err);
                reject(err);
            });
        });
    },

    [A.CREATE_REGISTRY]({ commit, dispatch }, { typeID, payload }) {
        return new Promise((resolve, reject) => {
            Api.createTypeRegistry(typeID, payload).then((resp) => {
                commit(M.SELECT_REGISTRY, resp.data);
                dispatch(A.LOAD_LIST, { typeID });
                resolve();
            }).catch((err) => {
                commit(M.ERROR, err);
                reject(err);
            });
        });
    },

    [A.UPDATE_REGISTRY]({ commit, dispatch }, { typeID, registryID, payload }) {
        return new Promise((resolve, reject) => {
            Api.updateTypeRegistry(typeID, registryID, payload).then((resp) => {
                commit(M.SELECT_REGISTRY, resp.data);
                dispatch(A.LOAD_LIST, { typeID });
                resolve();
            }).catch((err) => {
                commit(M.ERROR, err);
                reject(err);
            });
        });
    },

    [A.REMOVE_REGISTRY]({ commit, dispatch }, { typeID, registryID }) {
        return new Promise((resolve, reject) => {
            Api.removeTypeRegistry(typeID, registryID).then(() => {
                commit(M.SELECT_REGISTRY, null);
                dispatch(A.LOAD_LIST, { typeID });
                resolve();
            }).catch((err) => {
                commit(M.ERROR, err);
                reject(err);
            });
        });
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
