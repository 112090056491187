import _get from 'lodash/get';

import PagedList from '@/store/pagedlist';
import Api from '@/api';

import * as A from './actions';
import * as M from './mutations';

const page = new PagedList('pages');

const initState = {
    appID: null,
    list: [],
    pages: page.initState(),
    filters: {},
    selected: null,
    selectedLastTouch: 0,
};

const getters = { };

const mutations = {
    [M.LOAD_LIST](state, {
        appID, list, pagination, filters,
    }) {
        state.appID = appID;

        state.list = list;
        state.pages = page.readState(pagination);
        state.filters = filters;

        state.selected = null;
        state.selectedLastTouch = 0;
    },
    [M.SELECT_USER](state, selected) {
        state.selected = selected;
        state.appID = selected.scope.id;
        state.selectedLastTouch = new Date().getTime();
    },
    [M.RESET](state) {
        state.appID = null;
        state.list = [];
        state.pages = page.initState();
        state.filters = {};
        state.selected = null;
        state.selectedLastTouch = 0;
    },
};

const actions = {
    ...page.mapActions(),

    [A.UPDATE_LIST]({ dispatch, state }, { appID }) {
        return (page.obsolete(state) || appID !== state.appID)
            ? dispatch(A.LOAD_LIST, { appID }) : Promise.resolve();
    },

    [A.LOAD_LIST]({ commit, dispatch, state }, opts) {
        const appID = _get(opts, 'appID', state.appID);
        const pageOpts = page.pageOpts(state, opts);

        return new Promise((resolve, reject) => {
            Api.scopeUsers(appID, pageOpts).then((resp) => {
                dispatch('profile/UPDATE_NAV', { appID }, { root: true });
                commit(M.LOAD_LIST, { appID, ...resp.data });
                resolve(resp.data);
            }).catch((err) => {
                commit(M.RESET);
                reject(err);
            });
        });
    },

    [A.FILTER_TREE]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'tree', value });
    },
    [A.FILTER_SEARCH]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'search', value });
    },

    [A.CREATE_USER]({ commit }, { appID, payload }) {
        return new Promise((resolve, reject) => {
            Api.createScopeUser(appID, payload).then((resp) => {
                commit(M.SELECT_USER, resp.data);
                resolve(resp.data);
            }).catch(reject);
        });
    },

    [A.CREATE_EXTUSER]({ commit }, { appID, payload }) {
        return new Promise((resolve, reject) => {
            Api.createScopeExtUser(appID, payload).then((resp) => {
                commit(M.SELECT_USER, resp.data);
                resolve(resp.data);
            }).catch(reject);
        });
    },

    [A.SELECT_USER]({ commit, dispatch }, userID) {
        return new Promise((resolve, reject) => {
            Api.userInfo(userID).then((resp) => {
                commit(M.SELECT_USER, resp.data);
                dispatch('profile/UPDATE_NAV', { appID: resp.data.scopeID }, { root: true });
                resolve();
            }).catch(reject);
        });
    },

    [A.UPDATE_USER]({ commit, dispatch }, user) {
        return new Promise((resolve, reject) => {
            Api.updateUser(user.id, user).then((resp) => {
                dispatch(A.LOAD_LIST).then(() => {
                    commit(M.SELECT_USER, resp.data);
                    resolve();
                }).catch(reject);
            }).catch(reject);
        });
    },

    [A.MOVE_USER]({ dispatch }, { userID, scopeID }) {
        return new Promise((resolve, reject) => {
            Api.moveUser(userID, scopeID).then((resp) => {
                dispatch(A.LOAD_LIST).then(resolve, reject);
                dispatch('profile/UPDATE_NAV', { appID: resp.data.scopeID }, { root: true });
            }).catch(reject);
        });
    },

    [A.REMOVE_USER]({ dispatch }, userID) {
        return new Promise((resolve, reject) => {
            Api.removeUser(userID).then(() => {
                dispatch(A.LOAD_LIST).then(resolve, reject);
            }).catch(reject);
        });
    },

    [A.UPDATE_USER_AVATAR]({ commit }, payload) {
        const { userID, avatar } = payload;
        const data = new FormData();
        data.append('file', avatar);

        return new Promise((resolve, reject) => {
            Api.updateUserAvatar(userID, data).then(() => {
                Api.userInfo(userID).then((resp) => {
                    commit(M.SELECT_USER, resp.data);
                    resolve();
                }).catch(reject);
            }).catch(reject);
        });
    },

    /* eslint-disable no-empty-pattern */
    [A.UPDATE_USER_PASSWORD]({}, payload) {
        const { userID, password } = payload;
        return Api.updateUserPassword(userID, password);
    },

    [A.ADD_USER_ROLE]({ commit }, payload) {
        const { userID, role } = payload;
        return new Promise((resolve, reject) => {
            Api.createUserRole(userID, role).then(() => {
                Api.userInfo(userID).then((resp) => {
                    commit(M.SELECT_USER, resp.data);
                    resolve();
                }).catch(reject);
            }).catch(reject);
        });
    },

    [A.REMOVE_USER_ROLE]({ commit }, payload) {
        const { userID, roleID } = payload;
        return new Promise((resolve, reject) => {
            Api.removeUserRole(userID, roleID).then(() => {
                Api.userInfo(userID).then((resp) => {
                    commit(M.SELECT_USER, resp.data);
                    resolve();
                }).catch(reject);
            }).catch(reject);
        });
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
