<template>
    <div class="form-group">
        <label class="form-label">Логин</label>
        <input class="form-input" type="text" disabled
            v-model="info.username" placeholder="Логин пользователя">
        <label class="form-label">Имя</label>
        <input class="form-input" type="text" :disabled="disabled"
            v-model="info.firstName" placeholder="Имя пользователя">
        <label class="form-label">Отчество</label>
        <input class="form-input" type="text" :disabled="disabled"
            v-model="info.middleName" placeholder="Отчество пользователя">
        <label class="form-label">Фамилия</label>
        <input class="form-input" type="text" :disabled="disabled"
            v-model="info.lastName" placeholder="Фамилия пользователя">
        <label class="form-label">Электронная почта</label>
        <input class="form-input" type="text" :disabled="disabled"
            v-model="info.email" placeholder="Электронная почта">

        <label class="form-label">Мобильный телефон</label>
        <imask-input class="form-input" type="text"
               :disabled="disabled"
                @accept="info.mobilePhone.is_completed = false"
                @complete="info.mobilePhone.is_completed = true"
               :mask="info.mobilePhone.mask"
               v-model="info.mobilePhone.value"
               :unmask="false" />

        <label class="form-label">Внутренний телефон</label>
        <imask-input class="form-input" type="text"
               :disabled="disabled"
                @accept="info.localPhone.is_completed = false"
                @complete="info.localPhone.is_completed = true"
               :mask="info.localPhone.mask"
               v-model="info.localPhone.value"
               :unmask="false" />

        <label class="form-label">Телефон</label>
        <imask-input class="form-input" type="text"
               :disabled="disabled"
                @accept="info.phone.is_completed = false"
                @complete="info.phone.is_completed = true"
               :mask="info.phone.mask"
               v-model="info.phone.value"
               :unmask="false" />

        <label class="form-label">Должность</label>
        <input class="form-input" type="text" :disabled="disabled"
               v-model="info.position" placeholder="Должность">

        <label class="form-label">Описание</label>
        <textarea class="form-input" type="text" rows="3"
                  v-model="info.description" placeholder="Описание"/>

    </div>
</template>

<script>
import { IMaskComponent } from 'vue-imask';

function toMask(v, mask) {
    return { value: v, mask, is_completed: (v !== null && v !== undefined) };
}

function fromMask(v) {
    return v.is_completed ? v.value : undefined;
}

function cloneInfo(info) {
    const phoneMask = '+{7}(000)000-00-00';
    const localMask = '00-00';
    return {
        id: info.id,
        username: info.username,
        firstName: info.firstName,
        middleName: info.middleName,
        lastName: info.lastName,
        email: info.email,
        mobilePhone: toMask(info.mobilePhone, phoneMask),
        localPhone: toMask(info.localPhone, localMask),
        phone: toMask(info.phone, phoneMask),
        position: info.position,
        description: info.description,
    };
}

export default {
    name: 'UserProfileForm',
    props: ['user', 'disabled'],

    components: {
        ImaskInput: IMaskComponent,
    },

    data() {
        return {
            info: cloneInfo(this.user),
        };
    },

    computed: {
        changed() {
            return this.user != null
                && (this.info.firstName !== this.user.firstName
                || this.info.middleName !== this.user.middleName
                || this.info.lastName !== this.user.lastName
                || this.info.email !== this.user.email
                || fromMask(this.info.mobilePhone) !== this.user.mobilePhone
                || fromMask(this.info.localPhone) !== this.user.localPhone
                || fromMask(this.info.phone) !== this.user.phone
                || this.info.position !== this.user.position
                || this.info.description !== this.user.description);
        },

        payload() {
            return {
                id: this.info.id,
                username: this.info.username,
                firstName: this.info.firstName,
                middleName: this.info.middleName,
                lastName: this.info.lastName,
                email: this.info.email,
                mobilePhone: fromMask(this.info.mobilePhone),
                localPhone: fromMask(this.info.localPhone),
                phone: fromMask(this.info.phone),
                position: this.info.position,
                description: this.info.description,
            };
        },
    },

    watch: {
        changed(vv) {
            this.$emit('profile-changed', vv);
        },
    },

    methods: {
        reload() {
            this.info = cloneInfo(this.user);
        },
    },
};
</script>
