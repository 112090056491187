import _get from 'lodash/get';

import TablePagination from '@/components/common/TablePagination';
import TaskUserRef from './TaskUserRef';

export default {
    name: 'TaskUsers',
    props: {
        listUsers: {
            type: Function,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
        state: {
            type: Object,
        },
    },
    components: { TablePagination, TaskUserRef },

    data() {
        return {
            isLoading: false,
            errorMsg: null,

            list: [],
            pages: {
                page: 0,
                size: 10,
                count: 0,
            },
            lastTouch: 0,

            selected: [],

        };
    },

    computed: {
        count() { return this.selected.length; },
        users() { return this.selected; },
    },

    watch: {
        isOpen(v) {
            this.clear();
            if (v) {
                this.reload();
            }
        },
    },

    created() { this.reload(); },

    methods: {
        clear() {
            this.list = [];
            this.isLoading = false;
            this.pages.page = 0;
            this.pages.size = 10;
            this.pages.count = 0;
            this.lastTouch = 0;
            this.errorMsg = null;
            this.selected = [];
        },

        reload(opts) {
            const size = _get(opts, 'size', this.pages.size);
            const offset = _get(opts, 'page', this.pages.current) * size;

            this.listUsers({ offset, size })
                .then((resp) => {
                    const { list, pagination } = resp.data;
                    this.list = list;
                    this.pages.page = Math.floor(pagination.offset / pagination.pagesize);
                    this.pages.size = pagination.pagesize;
                    this.pages.count = Math.ceil(pagination.total / pagination.pagesize) - 1;
                    this.lastTouch = new Date().getTime();
                    this.errorMsg = null;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.clear();
                    this.errorMsg = err.message;
                });
        },

        changePage(page) {
            this.reload({ page: page || 0 });
        },

        switchUser(id) {
            const idx = this.selected.indexOf(id);
            if (idx >= 0) {
                this.selected.splice(idx, 1);
            } else {
                this.selected.push(id);
            }
        },

        hasUser(id) {
            return this.selected.indexOf(id) >= 0;
        },
    },
};
