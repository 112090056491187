<template>
    <div class="text-center" v-if="last > 0">
        <ul class="pagination">
            <li class="page-item c-hand" v-if="page > 1" @click="changePage(0)">
                <a>1</a>
            </li>
            <li class="page-item" v-if="page > 2">
                <span>...</span>
            </li>
            <li class="page-item c-hand" v-if="page > 0" @click="changePage(page - 1)">
                <a>{{page}}</a>
            </li>
            <li class="page-item active">
                <a>{{page + 1}}</a>
            </li>
            <li class="page-item c-hand" v-if="page < last" @click="changePage(page + 1)">
                <a>{{page + 2}}</a>
            </li>
            <li class="page-item" v-if="page + 2 < last">
                <span>...</span>
            </li>
            <li class="page-item c-hand" v-if="page + 1 < last" @click="changePage(last)">
                <a>{{last + 1}}</a>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
ul.pagination {
    justify-content: center;
}
</style>

<script>
export default {
    name: 'TablePagination',
    props: ['page', 'last'],

    methods: {
        changePage(page) {
            if (page !== this.page) {
                this.$emit('changed', page);
            }
        },
    },
};
</script>
