import _get from 'lodash/get';
import Api from '@/api';
import PagedList from '@/store/pagedlist';

import * as A from './actions';
import * as M from './mutations';

const page = new PagedList('pages');

const initState = {
    appID: null,
    list: [],
    pages: page.initState(),
    filters: {},
    available: {},
    selected: null,
};

const getters = { };

const mutations = {
    [M.LOAD_LIST](state, {
        appID, list, pagination, filters, available,
    }) {
        state.appID = appID;
        state.list = list;
        state.pages = page.readState(pagination);
        state.filters = filters;
        state.available = available;
        state.selected = null;
    },
    [M.SELECT_REPORT](state, selected) {
        state.selected = selected;
    },
    [M.RESET](state) {
        state.appID = null;
        state.list = [];
        state.pages = page.initState();
        state.filters = {};
        state.available = {};
        state.selected = null;
    },
};

const actions = {
    ...page.mapActions(),

    [A.UPDATE_LIST]({ dispatch, state }, { appID }) {
        return (page.obsolete(state) || appID !== state.appID)
            ? dispatch(A.LOAD_LIST, { appID }) : Promise.resolve();
    },

    [A.LOAD_LIST]({ commit, state }, opts) {
        const appID = _get(opts, 'appID', state.appID);
        const pageOpts = page.pageOpts(state, opts);

        return new Promise((resolve, reject) => {
            Api.scopeReports(appID, pageOpts).then((resp) => {
                commit(M.LOAD_LIST, { appID, ...resp.data });
                resolve(resp.data);
            }).catch((err) => {
                commit(M.RESET);
                reject(err);
            });
        });
    },

    [A.SELECT_REPORT]({ commit }, id) {
        return new Promise((resolve, reject) => {
            Api.scopeReportInfo(id).then((resp) => {
                commit(M.SELECT_REPORT, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.FILTER_TREE]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'tree', value });
    },

    [A.FILTER_FINISHED]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'all', value });
    },

    [A.FILTER_SCHEMA]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'schema', value });
    },

    [A.FILTER_STATE]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'state', value });
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
