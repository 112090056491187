<template>
    <div class="tile tile-centered">
        <div class="tile-icon">
            <div class="app-tile-icon">
                <i class="icon icon-flag centered"></i>
            </div>
        </div>
        <div class="tile-content">
            <p class="tile-title">
                {{task.scope.name}} / {{task.name}}
            </p>
            <p class="tile-subtitle text-gray">
                {{task.schema}} / {{task.state}}, выполнить до {{task.expire | formatDate}}
            </p>
        </div>
        <div class="tile-action">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TaskItem',
    props: ['task'],
};
</script>
