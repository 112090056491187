import Api from '@/api';

import * as A from './actions';

const UPDATE_SCOPE = 'UPDATE_SCOPE';
const UPDATE_PROFILE = 'UPDATE_PROFILE';
const RESET = 'RESET';

const init = {
    profile: undefined,
    scope: undefined,
    lastTouch: 0,
};

const hasRole = (roles, role) => (roles.filter(r => r[role] === true).length > 0);
const hasScopeRole = (roles, code, role) => (roles
    .filter(r => r[role] === true && code.startsWith(r.scope.code))
    .length > 0);

const rolesMap = f => ({
    isTypesManager: f('types'),
    isScopesManager: f('scopes'),
    isUsersManager: f('users'),
    isSchemasManager: f('schemas'),
    isProcessesManager: f('processes'),
    isRegistriesManager: f('registries'),
    isProcessesViewer: f('pviewer'),
});


const getters = {
    isDeveloper: state => (state.profile && (
        state.profile.username === 'alex' || state.profile.username === 'admin' || state.profile.username === 'maxwell'
    )),
    myRoles: state => (state.profile == null ? {} : rolesMap(r => hasRole(state.profile.roles, r))),
    scopeRoles: state => (state.scope == null || state.profile == null ? {}
        : rolesMap(r => hasScopeRole(state.profile.roles, state.scope.code, r))),
};

const mutations = {
    [UPDATE_PROFILE](state, userinfo) {
        state.profile = userinfo;
        state.lastTouch = new Date().getTime();
    },
    [UPDATE_SCOPE](state, scope) {
        state.scope = scope;
    },
    [RESET](state) {
        state.profile = null;
        state.scope = null;
        state.lastTouch = 0;
    },
};

const actions = {
    [A.INIT_PROFILE]({ commit }, userinfo) {
        return new Promise((resolve, reject) => {
            Api.scopeNavigation(userinfo.scope.id).then((resp) => {
                commit(UPDATE_PROFILE, userinfo);
                commit(UPDATE_SCOPE, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.UPDATE_INFO]({ commit }, userinfo) {
        return new Promise((resolve, reject) => {
            Api.updateProfile(userinfo).then((resp) => {
                commit(UPDATE_PROFILE, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.UPDATE_NAV]({ state, commit }, { appID, forced }) {
        return new Promise((resolve, reject) => {
            if (state.scope && appID === state.scope.id && forced !== true) {
                resolve();
                return;
            }
            Api.scopeNavigation(appID).then((resp) => {
                commit(UPDATE_SCOPE, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.UPDATE_AVATAR]({ commit }, avatarFile) {
        return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append('file', avatarFile);
            Api.updateAvatar(data).then(() => {
                Api.profile().then((resp) => {
                    commit(UPDATE_PROFILE, resp.data);
                    resolve();
                }).catch(reject);
            }).catch(reject);
        });
    },

    /* eslint-disable no-empty-pattern */
    [A.UPDATE_PASSWORD]({}, password) {
        return new Promise((resolve, reject) => {
            Api.updatePassword(password).then(resolve).catch((error) => {
                if (error.response.status === 400) {
                    reject(new Error('Неверно введен старый пароль'));
                } else {
                    reject(new Error('Произошла неизвестная ошибка'));
                }
            });
        });
    },
};

export default {
    namespaced: true,
    state: init,
    getters,
    actions,
    mutations,
};
