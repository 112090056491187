<template>
    <div class="msgbox-area">
        <transition-group name="msg-list" tag="div">
            <div v-for="msg in app.messagesList" :key="msg.id" class="msgbox-box">
                <div v-if="msg.taskID" class="toast toast-success">
                    <button class="btn btn-clear float-right" @click="removeMsg(msg)"></button>
                    <div class="text-left link" @click="gotoTask(msg)">
                        {{msg.title}} <br> {{msg.body}}
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<style lang="scss" scoped>

$msgbox-area-width-l: 480px;

$msgbox-area-y-pos: 50px;
$msgbox-area-x-s: 20px;
$msgbox-area-x-m: 80px;
$msgbox-area-x-l: 15px;

.msg-list-enter-active,
.msg-list-leave-active,
.msg-list-move {
  transition: 300ms;
}

.msg-list-enter {
  opacity: 0;
  transform: translateX($msgbox-area-width-l);
}

.msg-list-leave-to {
  opacity: 0;
  transform: translateX($msgbox-area-width-l);
}

.msg-list-enter-to .msg-list-leave {
  opacity: 1;
}

.msg-list-leave-active {
  position: absolute;
}

.msgbox-area {
    z-index: 2;
    max-height: 100%;
    position: fixed;
    bottom: $msgbox-area-y-pos;
    left: $msgbox-area-x-s;
    right: $msgbox-area-x-s;

    .toast {
        margin-top: 5px;

        .link {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .msgbox-area {
        left: $msgbox-area-x-m;
        right: $msgbox-area-x-m;
    }
}

@media (min-width: 768px) {
    .msgbox-area {
        width: $msgbox-area-width-l;
        height: 0;
        top: $msgbox-area-y-pos;
        left: auto;
        right: $msgbox-area-x-l;
    }
}
</style>

<script>

import { mapState, mapActions } from 'vuex';
import { REMOVE_MSG } from '@/store/actions';

export default {
    name: 'MessagesList',
    computed: {
        ...mapState(['app']),
    },

    methods: {
        ...mapActions({ removeMsg: REMOVE_MSG }),

        gotoTask(msg) {
            this.removeMsg(msg);
            this.$router.push({ name: 'task-info', params: { taskID: msg.taskID } });
        },
    },
};
</script>
