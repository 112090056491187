import Api from '@/api';

import * as A from './actions';
import * as M from './mutations';

import movable from './movable';

const initState = {
    process: null,
    lastTouch: 0,
};

const getters = { };

const mutations = {
    [M.LOAD](state, processInfo) {
        state.process = processInfo;
        state.lastTouch = new Date().getTime();
    },
    [M.RESET](state) {
        state.process = null;
        state.lastTouch = 0;
    },
};

const actions = {
    [A.SELECT_PROCESS]({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('movable/RESET');

            Api.processAdminInfo(id).then((resp) => {
                commit(M.LOAD, resp.data);
                dispatch('profile/UPDATE_NAV', { appID: resp.data.scope.id }, { root: true });
                resolve();
            }).catch(reject);
        });
    },

    [A.UPDATE_PROCESS]({ commit }, { id, stateID, payload }) {
        return new Promise((resolve, reject) => {
            Api.updateProcess(id, stateID, payload).then((resp) => {
                commit(M.LOAD, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.PATCH_PROCESS]({ commit }, { id, payload }) {
        return new Promise((resolve, reject) => {
            Api.patchProcess(id, payload).then((resp) => {
                commit(M.LOAD, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.REVERT_PROCESS]({ commit }, processID) {
        return new Promise((resolve, reject) => {
            Api.revertProcess(processID).then((resp) => {
                commit(M.LOAD, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.MOVE_PROCESS]({ commit, dispatch }, { processID, scopeID }) {
        return new Promise((resolve, reject) => {
            Api.moveProcess(processID, scopeID).then((resp) => {
                commit(M.LOAD, resp.data);
                commit('scope/processes/RESET_APP', null, { root: true });
                dispatch('profile/UPDATE_NAV', { appID: resp.data.scope.id }, { root: true });
                resolve();
            }).catch(reject);
        });
    },

    [A.PATCH_TASK]({ commit }, { processID, taskID, payload }) {
        return new Promise((resolve, reject) => {
            Api.patchProcessTask(processID, taskID, payload).then((resp) => {
                commit(M.LOAD, resp.data);
                resolve();
            }).catch(reject);
        });
    },

    [A.NOTIFY_PROCESS](_, { processID }) {
        return Api.notifyProcess(processID);
    },

    [A.REMOVE_PROCESS]({ commit }, processID) {
        return new Promise((resolve, reject) => {
            Api.removeProcess(processID).then(() => {
                commit(M.RESET);
                resolve();
            }).catch(reject);
        });
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
    modules: {
        movable,
    },
};
