// Данный файл используется просто для предварительной
// загрузки в рамках работы webpack-bundle-splitter
export * from 'vue2-datepicker';
export * from 'vue-imask';

export * from '@/components/user/UserProfileForm.vue';
export * from '@/components/user/UserAvatarForm.vue';
export * from '@/components/common/FileUpload.vue';
export * from '@/components/common/TablePagination.vue';
export * from '@/components/common/TablePageSize.vue';
export * from '@/components/common/SearchBox.vue';
export * from '@/components/common/UploadButton.vue';
export * from '@/components/tasks/TaskHistory.vue';
export * from '@/components/tasks/TaskUsers.vue';
export * from '@/components/tasks/TaskForm.vue';
export * from '@/components/scope/AppAttrList.vue';
