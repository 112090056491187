import _get from 'lodash/get';

import TablePagination from '@/components/common/TablePagination';
import TaskItem from './TaskItem';

export default {
    name: 'TaskReports',
    props: {
        listReports: {
            type: Function,
        },
    },
    components: { TablePagination, TaskItem },

    data() {
        return {
            isLoading: false,
            errorMsg: null,

            list: [],
            pages: {
                page: 0,
                size: 10,
                count: 0,
            },
        };
    },

    created() { this.reload(); },

    methods: {
        clear() {
            this.list = [];
            this.isLoading = false;
            this.pages.page = 0;
            this.pages.size = 10;
            this.pages.count = 0;
            this.errorMsg = null;
        },

        reload(opts) {
            const size = _get(opts, 'size', this.pages.size);
            const offset = _get(opts, 'page', this.pages.current) * size;

            this.listReports({ offset, size })
                .then((resp) => {
                    const { list, pagination } = resp.data;
                    this.list = list;
                    this.pages.page = Math.floor(pagination.offset / pagination.pagesize);
                    this.pages.size = pagination.pagesize;
                    this.pages.count = Math.ceil(pagination.total / pagination.pagesize) - 1;
                    this.errorMsg = null;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.clear();
                    this.errorMsg = err.message;
                });
        },

        changePage(page) {
            this.reload({ page: page || 0 });
        },
    },
};
