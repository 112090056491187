export * from '@/store/pagedlist/actions';

export const FILTER_TREE = 'FILTER_TREE';
export const FILTER_SEARCH = 'FILTER_SEARCH';

export const SELECT_USER = 'SELECT_USER';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_EXTUSER = 'CREATE_EXTUSER';
export const MOVE_USER = 'MOVE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';

export const ADD_USER_ROLE = 'ADD_USER_ROLE';
export const REMOVE_USER_ROLE = 'REMOVE_USER_ROLE';
