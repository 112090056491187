import { mapGetters, mapState } from 'vuex';

import { LOGOUT } from '@/store/actions';

export default {
    name: 'AppHeader',

    data() {
        return {
            profileMenu: false,
        };
    },

    computed: {
        ...mapGetters(['isLogged']),
        ...mapState('profile', { profile: 'profile', profileTouch: 'lastTouch' }),

        hasSystemInfo() {
            return this.profile && this.profile.system != null;
        },
    },

    methods: {
        logout() {
            this.$store.dispatch(LOGOUT);
        },
    },
};
