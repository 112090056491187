<template>
    <div>
        <app-loading v-if="isLoading" />
    </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import { LIST_AUTHS } from '@/store/actions';

import AppLoading from '@/components/common/AppLoading';

export default {
    name: 'AppAutoAuth',
    components: { AppLoading },

    data() {
        return {
            isLoading: true,
        };
    },

    computed: {
        ...mapState(['app']),
    },

    methods: {
        ...mapActions({
            listAuths: LIST_AUTHS,
        }),
    },

    mounted() {
        const auth = this.$route.query.state;
        if (!auth) {
            this.$router.replace({ name: 'default' });
            return;
        }

        this.listAuths().then(() => {
            const prov = this.app.authList.find(a => a.code === auth);
            if (prov) {
                window.location.href = prov.url;
            } else {
                this.$router.replace({ name: 'default' });
            }
        }).catch(() => {
            this.$router.replace({ name: 'default' });
        });
    },
};

</script>
