import Api from '@/api';

import * as A from './actions';
import * as M from './mutations';

import scopes from './scopes';
import types from './types';
import schemas from './schemas';
import processes from './processes';
import reports from './reports';
import users from './users';
import tasks from './tasks';
import registries from './registries';
import registryrows from './registryrows';

// TODO: Заменить это на сервер
function readScopeAttr(type, attr) {
    const attribute = attr;
    const tt = type.attrs.find(a => a.id === attr.attr) || { options: null };
    attribute.options = tt.options;
    return attribute;
}

const initState = {
    scope: null,
    error: null,
};

const getters = { };

const mutations = {
    [M.LOAD](state, payload) {
        state.scope = payload;
        state.scope.attrs = payload.attrs.map(a => readScopeAttr(payload.type, a));
        state.error = null;
    },
    [M.ERROR](state, error) {
        state.error = error;
    },
    [M.RESET](state) {
        state.scope = null;
    },
};

const actions = {
    [A.LOAD_SCOPE]({ commit, dispatch }, appID) {
        return new Promise((resolve, reject) => {
            Api.scope(appID).then((resp) => {
                commit(M.LOAD, resp.data);
                dispatch('profile/UPDATE_NAV', { appID }, { root: true });
                resolve();
            }).catch(reject);
        });
    },
    [A.CREATE_SCOPE]({ commit, dispatch }, { appID, typeID, payload }) {
        return new Promise((resolve, reject) => {
            Api.createScope(appID, typeID, payload).then(() => {
                dispatch('scopes/LOAD_LIST');
                Api.scope(appID).then((resp) => {
                    commit(M.LOAD, resp.data);
                    resolve();
                }).catch(reject);
            }).catch(reject);
        });
    },
    [A.UPDATE_SCOPE]({ commit, dispatch }, { appID, payload }) {
        return new Promise((resolve, reject) => {
            Api.updateScope(appID, payload).then((resp) => {
                dispatch('profile/UPDATE_NAV', { appID }, { root: true });
                commit(M.LOAD, resp.data);
                resolve();
            }).catch(reject);
        });
    },
    [A.REMOVE_SCOPE]({ commit }, { appID, force = false }) {
        return new Promise((resolve, reject) => {
            Api.removeScope(appID, { force }).then(() => {
                commit('scopes/RESET');
                commit(M.RESET, null);
                resolve();
            }).catch(reject);
        });
    },
    [A.IMPORT_SCOPE]({ commit, dispatch, state }, payload) {
        const { id } = state.scope;
        return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append('file', payload);
            Api.importScopePack(id, data).then(() => {
                dispatch('scopes/LOAD_LIST');
            }).catch((err) => {
                commit(M.ERROR, err);
                reject(err);
            });
        });
    },
    [A.ADD_FILE]({ commit, state }, payload) {
        const { id } = state.scope;
        return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append('file', payload);
            Api.addScopeFile(id, data).then(() => {
                Api.scope(id).then((resp) => {
                    commit(M.LOAD, resp.data);
                    resolve();
                }).catch(reject);
            }).catch(reject);
        });
    },
    [A.REMOVE_FILE]({ commit, state }, fileID) {
        const { id } = state.scope;
        return new Promise((resolve, reject) => {
            Api.removeScopeFile(id, fileID).then(() => {
                Api.scope(id).then((resp) => {
                    commit(M.LOAD, resp.data);
                    resolve();
                }).catch(reject);
            }).catch(reject);
        });
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
    modules: {
        scopes,
        types,
        users,
        tasks,
        schemas,
        reports,
        processes,
        registries,
        registryrows,
    },
};
