import _get from 'lodash/get';
import Api from '@/api';
import PagedList from '@/store/pagedlist';

import * as A from './actions';
import * as M from './mutations';

const page = new PagedList('pages');

const initState = {
    processID: null,
    list: [],
    pages: page.initState(),
    filters: {},
    available: {},
};

const getters = { };

const mutations = {
    [M.LOAD_LIST](state, {
        processID, list, pagination, filters, available,
    }) {
        state.processID = processID;
        state.list = list;
        state.pages = page.readState(pagination, filters);
        state.filters = filters;
        state.available = available;
    },
    [M.RESET](state) {
        state.processID = null;
        state.list = [];
        state.filters = null;
        state.available = null;
        state.pages = page.initState();
    },
};

const actions = {
    ...page.mapActions(),

    [A.UPDATE_LIST]({
        dispatch, state, rootState, commit,
    }) {
        if (!rootState.mprocess.process) {
            commit(M.RESET);
            return Promise.resolve();
        }
        const processID = rootState.mprocess.process.pid;
        return (page.obsolete(state) || processID !== state.processID)
            ? dispatch(A.LOAD_LIST, { processID }) : Promise.resolve();
    },

    [A.LOAD_LIST]({ commit, state }, opts) {
        const processID = _get(opts, 'processID', state.processID);
        const pageOpts = page.pageOpts(state, opts);

        return new Promise((resolve, reject) => {
            Api.listProcessMoveScopes(processID, pageOpts).then((resp) => {
                commit(M.LOAD_LIST, { processID, ...resp.data });
                resolve(resp.data);
            }).catch((err) => {
                commit(M.RESET);
                reject(err);
            });
        });
    },

    [A.FILTER_TYPE]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'type', value });
    },
    [A.FILTER_ATTR]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'attr', value, reset: ['value'] });
    },
    [A.FILTER_VALUE]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'value', value });
    },

    [A.FILTER_SEARCH]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'search', value });
    },
    [A.FILTER_ORDER]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'orderBy', value });
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
