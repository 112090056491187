<template>
    <div class="has-icon-left">
        <input class="form-input" placeholder="Поиск"
            :value="text" v-on:input="oninput($event)" />
        <i class="form-icon icon icon-search"></i>
        <i v-if="isLoading || isRequest" class="search-box loading"></i>
    </div>
</template>

<style scoped>
i.search-box {
    height: .8rem;
    margin: 0 .25rem;
    position: absolute;
    right: 0.05rem;
    top: 50%;
    transform: translateY(-50%);
    width: .8rem;
    z-index: 2;
}
</style>

<script>
import _debounce from 'lodash/debounce';

export default {
    name: 'SearchBox',
    props: ['value', 'isRequest'],

    data() {
        return {
            text: this.value,
            isLoading: false,
        };
    },

    watch: {
        value(v) {
            if (!this.isLoading && !this.isRequest) {
                this.text = v;
            }
        },
    },

    created() {
        this.debounceUpdate = _debounce(this.update, 500);
    },

    methods: {
        oninput(e) {
            this.$emit('input', e.target.value);
            this.isLoading = true;
            this.text = e.target.value;
            this.debounceUpdate();
        },

        update() {
            this.isLoading = false;
            this.$emit('change');
        },
    },
};
</script>
