import axios from 'axios';

const TOKEN_KEY = 'app_token';
const REFRESH_KEY = 'refresh_token';
const USERNAME_KEY = 'username';
const DEVICE_ID_KEY = 'device';

const api = axios.create({ baseURL: '/api' });

let RefreshToken = null;
let UserName = null;
let DeviceId = null;
const App = 'admin';

// Функции работы с токенам аутенфикации
function storeToken(username, token, refresh, deviceID) {
    api.defaults.headers.common.AUTHTOKEN = token;
    RefreshToken = refresh;
    UserName = username;
    DeviceId = deviceID;
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(REFRESH_KEY, refresh);
    localStorage.setItem(USERNAME_KEY, username);
    localStorage.setItem(DEVICE_ID_KEY, deviceID);
}

function loadToken(resp) {
    const {
        // eslint-disable-next-line camelcase
        username, token, refresh_token, device_id,
    } = resp.data;
    storeToken(username, token, refresh_token, device_id);
    return resp;
}

function resetToken() {
    delete api.defaults.headers.AUTHTOKEN;
    RefreshToken = null;
    UserName = null;
    DeviceId = null;
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_KEY);
    localStorage.removeItem(USERNAME_KEY);
    localStorage.removeItem(DEVICE_ID_KEY);
}

// Инициализация из localStorage
storeToken(
    localStorage.getItem(USERNAME_KEY),
    localStorage.getItem(TOKEN_KEY),
    localStorage.getItem(REFRESH_KEY),
    localStorage.getItem(DEVICE_ID_KEY),
);


// Функции по работе с опциями запросов
function pageOpts(opts) {
    const params = {
        offset: 0,
        size: 10,
    };
    if (opts && opts.offset) params.offset = Number(opts.offset);
    if (opts && opts.size) params.size = Number(opts.size);

    if (opts && opts.filters) {
        Object.keys(opts.filters).forEach((key) => {
            params[key] = opts.filters[key];
        });
    }

    return params;
}

function actionOpts(opts) {
    const params = {};
    if (opts.force) params.force = true;
    return params;
}

const Proxy = {
    // Установка фильтра аутенфикации c повторым выполнение запроса при ошибке 401
    initAuthInteceptor(reset) {
        api.interceptors.response.use(undefined, (error) => {
            const { response, config } = error;
            // Обработка ошибки аутенцикации
            if (response && config && response.status === 401
                && RefreshToken && UserName && !config.skip401) {
                // Пытаемся обновить токен
                return Proxy.loginRefresh(DeviceId)
                    .then((resp) => { // В случае успеха повторяем запрос c новым токеном
                        config.skip401 = true;
                        config.headers.AUTHTOKEN = resp.data.token;
                        return api.request(config);
                    })
                    .catch((err) => { // В случае неудачи очищаем все токены
                        reset();
                        return Promise.reject(err);
                    });
            }

            if (response && response.status === 401) {
                reset();
            }

            return Promise.reject(error);
        });
    },

    // Логин/логаут
    loginPassword: (username, password) => api.post('login/app', { username, password, app: App }, { skip401: true }).then(loadToken),
    loginRefresh: id => api.post('login/app', {
        username: UserName,
        app: App,
        refresh_token: RefreshToken,
        device_id: id,
    }, { skip401: true }).then(loadToken),
    logout: () => api.post('logout').finally(resetToken),
    updateDeviceID: id => ((id !== DeviceId) ? Proxy.loginRefresh(id) : Promise.resolve()),

    listAuths: () => api.get('auths'),
    completeAuth: (auth, payload) => api.post(`auths/${auth}/complete`, payload),

    // Работа с профилем пользователя
    profile: () => api.get('profile'),
    updateProfile: payload => api.put('profile', payload),
    updateAvatar: payload => api.put('profile/avatar.png', payload),
    updatePassword: payload => api.post('profile/password', payload),

    // Работа с областями видимости
    scope: id => api.get(`scopes/${Number(id)}`),
    scopeNavigation: id => api.get(`scopes/${Number(id)}/nav`),
    scopeRoles: id => api.get(`scopes/${Number(id)}/roles`),
    scopeType: (id, typeID) => api.get(`scopes/${Number(id)}/types/${Number(typeID)}`),

    // Получение основых данных раздела
    scopeSubscopes: (id, opts) => api.get(`scopes/${Number(id)}/scopes`, { params: pageOpts(opts) }),
    scopeReports: (id, opts) => api.get(`scopes/${Number(id)}/reports`, { params: pageOpts(opts) }),
    scopeSchemas: (id, opts) => api.get(`scopes/${Number(id)}/schemas`, { params: pageOpts(opts) }),
    scopeProcesses: (id, opts) => api.get(`scopes/${Number(id)}/processes`, { params: pageOpts(opts) }),
    scopeTypes: id => api.get(`scopes/${Number(id)}/types`),
    scopeUsers: (id, opts) => api.get(`scopes/${Number(id)}/users`, { params: pageOpts(opts) }),
    scopeTasks: (id, opts) => api.get(`scopes/${Number(id)}/tasks`, { params: pageOpts(opts) }),
    scopeRegistries: (id, opts) => api.get(`scopes/${Number(id)}/registries`, { params: pageOpts(opts) }),
    scopeRegistryRows: (id, rid, opts) => api.get(`scopes/${Number(id)}/registries/${Number(rid)}`, { params: pageOpts(opts) }),

    // Операции над данными реестров
    createRegistryRow: (id, rid, payload) => api.post(`scopes/${Number(id)}/registries/${Number(rid)}`, payload),
    fetchRegistryRow: (id, rid) => api.get(`scopes/${Number(id)}/rows/${Number(rid)}`),
    updateRegistryRow: (id, rid, payload) => api.put(`scopes/${Number(id)}/rows/${Number(rid)}`, payload),
    removeRegistryRow: (id, rid) => api.delete(`scopes/${Number(id)}/rows/${Number(rid)}`),

    // Операции над разделами
    createScope: (id, typeID, payload) => api.post(`scopes/${Number(id)}/scopes/${Number(typeID)}`, payload),
    updateScope: (id, payload) => api.put(`scopes/${Number(id)}`, payload),
    removeScope: (id, opts) => api.delete(`scopes/${Number(id)}`, { params: actionOpts(opts) }),
    importScopePack: (id, payload) => api.post(`scopes/${Number(id)}/import`, payload),

    runScopeAction: (id, code) => api.post(`scopes/${Number(id)}/action/${String(code)}`, null),

    // Операции над данными раздела
    createScopeType: (id, payload) => api.post(`scopes/${Number(id)}/types`, payload),
    createScopeUser: (id, payload) => api.post(`scopes/${Number(id)}/users`, payload),
    createScopeExtUser: (id, payload) => api.post(`scopes/${Number(id)}/ext-users`, payload),

    addScopeFile: (id, payload) => api.post(`scopes/${Number(id)}/files`, payload),
    removeScopeFile: (id, fileID) => api.delete(`files/s/${Number(id)}/${Number(fileID)}`),

    startProcess: (id, stateID, payload) => api.post(`scopes/${Number(id)}/processes/${Number(stateID)}`, payload),

    // Работа с типами областей видимости и ролями
    type: id => api.get(`types/${Number(id)}`),
    updateType: (id, payload) => api.put(`types/${Number(id)}`, payload),
    removeType: id => api.delete(`types/${Number(id)}`),

    createMetaType: (id, payload) => api.post(`types/${Number(id)}/types`, payload),
    typeMetaTypes: id => api.get(`types/${Number(id)}/types`),

    createRole: (id, payload) => api.post(`types/${Number(id)}/roles`, payload),
    typeRoles: id => api.get(`types/${Number(id)}/roles`),
    updateRole: (id, payload) => api.put(`roles/${Number(id)}`, payload),
    removeRole: id => api.delete(`roles/${Number(id)}`),

    typeAttributeValues: (id, attrId) => api.get(`/types/${Number(id)}/attrs/${Number(attrId)}/values`),
    validateScopeTypeCode: code => api.post('/types/validate', { code }),

    // Работа с реестрами
    listTypeRegistries: (id, opts) => api.get(`types/${Number(id)}/registries`, { params: pageOpts(opts) }),
    createTypeRegistry: (id, payload) => api.post(`types/${Number(id)}/registries`, payload),
    fetchTypeRegistry: (id, rid) => api.get(`types/${Number(id)}/registries/${Number(rid)}`),
    updateTypeRegistry: (id, rid, payload) => api.put(`types/${Number(id)}/registries/${Number(rid)}`, payload),
    removeTypeRegistry: (id, rid) => api.delete(`types/${Number(id)}/registries/${Number(rid)}`),

    // Работа с пользователями
    userInfo: id => api.get(`users/${Number(id)}`),
    updateUser: (id, payload) => api.put(`users/${Number(id)}`, payload),
    moveUser: (id, scopeID) => api.post(`users/${Number(id)}/move/${scopeID}`),
    removeUser: id => api.delete(`users/${Number(id)}`),
    updateUserAvatar: (id, payload) => api.put(`users/${Number(id)}/avatar`, payload),
    updateUserPassword: (id, payload) => api.post(`users/${Number(id)}/password`, payload),

    createUserRole: (id, payload) => api.post(`users/${Number(id)}/roles`, payload),
    removeUserRole: (id, roleID) => api.delete(`users/${Number(id)}/roles/${Number(roleID)}`),

    validateUsername: username => api.post('/users/validate', { username }),

    // Работа со схемами процессов
    uploadProcessSchema: (id, payload) => api.post(`scopes/${Number(id)}/schemas`, payload),
    processSchema: id => api.get(`schemas/${Number(id)}`),
    updateProcessSchema: (id, payload) => api.put(`schemas/${Number(id)}`, payload),
    removeProcessSchema: id => api.delete(`schemas/${Number(id)}`),
    cloneSchemaProcesses: (id, sourceID) => api.post(`schemas/${Number(id)}/clone/${Number(sourceID)}`, null),

    // Работа с процессами от лица куратора
    processByCurator: id => api.get(`processes/c/${Number(id)}`),
    listProcessesByCurator: opts => api.get('processes', { params: pageOpts(opts) }),

    // Работа с процессам от лица менеджера
    processAdminInfo: id => api.get(`mprocesses/${Number(id)}`),
    updateProcess: (id, stateID, payload) => api.post(`mprocesses/${Number(id)}/${Number(stateID)}`, payload),
    patchProcess: (id, payload) => api.put(`mprocesses/${Number(id)}`, payload),
    revertProcess: id => api.post(`mprocesses/${Number(id)}/revert`, null),
    moveProcess: (id, scopeID) => api.post(`mprocesses/${Number(id)}/move/${Number(scopeID)}`, null),
    patchProcessTask: (id, taskID, payload) => api.put(`mprocesses/${Number(id)}/tasks/${Number(taskID)}`, payload),
    notifyProcess: id => api.post(`mprocesses/${Number(id)}/notify`, null),
    removeProcess: id => api.delete(`mprocesses/${Number(id)}`),

    listProcessFormScopes: (id, formID, opts) => api.get(`mprocesses/${Number(id)}/${Number(formID)}/scopes`, { params: pageOpts(opts) }),
    listProcessMoveScopes: (id, opts) => api.get(`mprocesses/${Number(id)}/move/scopes`, { params: pageOpts(opts) }),
    listProcessNotRunnedScopes: (id, opts) => api.get(`mprocesses/${Number(id)}/scopes`, { params: pageOpts(opts) }),
    listProcessExecutors: (id, stateID, opts) => api.get(`mprocesses/${Number(id)}/${Number(stateID)}/users`, { params: pageOpts(opts) }),
    listProcessSubProcesses: (id, stateID, opts) => api.get(`mprocesses/${Number(id)}/${Number(stateID)}/processes`, { params: pageOpts(opts) }),

    // Работа с отчетами
    listReports: opts => api.get('reports', { params: pageOpts(opts) }),
    reportInfo: id => api.get(`reports/s/${Number(id)}`),
    scopeReportInfo: id => api.get(`reports/m/${Number(id)}`),
    fetchTaskValue: id => api.get(`reports/v/${Number(id)}`),
    taskValueFileURL: (id, fid) => `/api/reports/v/${Number(id)}/file/${Number(fid)}/content`,

    // Работа с задачами
    listTasks: opts => api.get('tasks', { params: pageOpts(opts) }),
    listTaskRefs: () => api.get('tasks/refs'),
    taskInfo: id => api.get(`tasks/${Number(id)}`),
    listTaskExecutors: (id, actionID, opts) => api.get(`tasks/${Number(id)}/${Number(actionID)}/users`, { params: pageOpts(opts) }),
    listTaskScopes: (id, actionID, opts) => api.get(`tasks/${Number(id)}/${Number(actionID)}/scopes`, { params: pageOpts(opts) }),
    listTaskReports: (id, stateID, opts) => api.get(`tasks/${Number(id)}/${Number(stateID)}/reports`, { params: pageOpts(opts) }),
    completeTask: (id, actionID, payload) => api.post(`tasks/${Number(id)}/${Number(actionID)}`, payload),

    // Работа с файлами
    uploadFile: payload => api.post('files', payload),
    removeFile: id => api.delete(`files/s/${Number(id)}`),

    // Адреса для скачивания отчетов
    scopeProcessesReportURL: id => `/api/export/scope/${Number(id)}`,
    // scopeProcessesReportURL: id => `/api/scopes/${Number(id)}/projects/mercy`,
    processSchemaReportURL: id => `/api/export/schema/${Number(id)}`,
    processReportURL: id => `/api/export/process/${Number(id)}`,
    process2ReportURL: id => `/api/export/process2/${Number(id)}`,
    process3ReportURL: id => `/api/export/process3/${Number(id)}`,

    // Адреса для внешних ссылок
    taskLinkURL: (tid, id) => `/api/tasks/${Number(tid)}/link/${Number(id)}`,
    processLinkURL: (pid, id) => `/api/processes/m/${Number(pid)}/link/${Number(id)}`,

    processDumpURL: id => `/api/export/process/${Number(id)}/dump`,
    importProcessDump: (id, payload) => api.post(`reports/m/${Number(id)}/import`, payload),
};

export default Proxy;
