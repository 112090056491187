<template>
    <div class="history timeline bg-gray text-left px-2">
        <div class="text-right" v-if="process.links">
            <a v-for="link in process.links" :key="link.id"
                target="_blank" rel="noopener noreferrer"
                :href="linkUrl(link)"
                class="btn btn-link">{{link.name}}</a>
        </div>

        <div class="timeline-item">
            <div class="timeline-left">
                <span class="timeline-icon"></span>
            </div>
            <div class="timeline-content">
                <i>{{process.create | formatDate}} создано {{process.initiator | userFullName }}</i>
            </div>
        </div>

        <details class="timeline-item accordion" :key="idx" v-for="(step, idx) in process.history">
            <summary class="accordion-header timeline-left">
                <span class="timeline-icon icon-lg">
                    <i class="icon icon-arrow-right"></i>
                </span>
                <b class="c-hand">{{step.state.state}}</b>
            </summary>
            <div class="accordion-body">
                <div :key="task.id" v-for="task in step.tasks">
                    <i>{{task.execute | formatDate }} выполнено
                        {{task.executor | userFullName }}</i>
                    <task-value v-for="value in task.values" :key="value.id"
                        :taskID="task.id" :value="value" />
                </div>
            </div>
        </details>

        <div class="timeline-item timeline-end">
            <div class="timeline-left">
                <span class="timeline-icon icon-lg">
                    <i class="icon icon-check"></i>
                </span>
            </div>
            <div class="timeline-content">
                <div class="tile-content">
                    <b class="m-0">{{process.state}}</b>
                    <div class="columns">
                        <div class="column col-12"
                            :key="task.id" v-for="task in process.tasks">
                            {{task.execute | formatDate }}
                            выполнено
                            {{task.executor | userFullName }}
                            <task-value v-for="value in task.values" :key="value.id"
                                :taskID="task.id" :value="value" />
                        </div>
                    </div>

                    <div v-if="process.processes && listReports">
                        <i>{{process.processes.active}} из {{process.processes.all}}
                            процессов в работе</i>
                        <task-reports :listReports="listStateReports(process.id)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" src="@/styles/processHistory.scss" ></style>

<script>

import TaskValue from './TaskValue';
import TaskReports from './TaskReports';

export default {
    name: 'TaskHistory',
    props: {
        process: Object,
        listReports: Function,
        linkUrl: Function,
    },
    components: { TaskValue, TaskReports },
    methods: {
        listStateReports(stateID) {
            return opts => this.listReports(stateID, opts);
        },
    },
};
</script>
