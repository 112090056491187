<template>
    <div class="app-loading">
        <div class="loading loading-lg"></div>
    </div>
</template>

<style lang="scss" scoped>
.app-loading {
    position: fixed;
    width: 100px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
</style>

<script>

export default {
    name: 'AppLoading',
};

</script>
