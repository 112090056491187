import DatePicker from 'vue2-datepicker';

import Api from '@/api';

import FileUpload from '@/components/common/FileUpload';
import FileInfo from '@/components/common/FileInfo';

import TaskUsers from './TaskUsers.vue';
import TaskScopes from './TaskScopes.vue';
import TaskHistory from './TaskHistory';

function fromItem(item) {
    const value = {
        id: item.id,
        name: item.name,
        type: item.type,
        is_required: item.is_required,
        description: item.description,
        value: null,
    };
    if (item.type === 'enum') {
        value.list = JSON.parse(item.options).options;
    }
    if (item.type === 'media') {
        value.files = [];
    }
    if (item.type === 'label') {
        value.label = item.options;
    }

    return value;
}

function validateValue(item) {
    if (!item.is_required && (item.value == null || item.value.length === 0)) {
        return true;
    }

    if (item.type === 'string' || item.type === 'text') {
        return item.value !== null && item.value.length > 0;
    }
    if (item.type === 'number') {
        return !Number.isNaN(parseFloat(item.value));
    }
    if (item.type === 'enum') {
        return item.list.filter(i => i.id === Number(item.value)).length > 0;
    }
    if (item.type === 'media') {
        return item.files.length > 0;
    }

    return false;
}

function toPayload(item) {
    const payload = {
        item: item.id,
    };
    if (item.type === 'media') {
        if (item.files.length === 0) {
            return null;
        }
        payload.files = item.files.map(f => f.id);
    } else {
        if (item.value === null) {
            return null;
        }
        payload.value = item.value;
    }
    return payload;
}

export default {
    name: 'TaskForm',
    components: {
        FileUpload, FileInfo, TaskUsers, TaskHistory, DatePicker, TaskScopes,
    },
    props: {
        process: { type: Object },
        listUsers: { type: Function },
        listScopes: { type: Function },
        listReports: { type: Function },
        linkUrl: { type: Function },
    },

    data() {
        return {
            state: null,

            showForm: false,

            values: [],
            expireDate: null,

            errorMsg: null,
        };
    },

    computed: {
        showUsers() {
            return this.state != null && (this.state.type === 'parallel' || this.state.type === 'assigned');
        },
        showScopes() {
            return this.state != null && this.state.type === 'subprocess' && this.state.subprocess !== null;
        },
    },

    mounted() {
        this.openItems();
    },

    watch: {
        process() {
            this.openItems();
        },
    },

    methods: {
        openForm(state) {
            // Сбрасываем состояние формы
            this.state = state;
            this.selectFiles = false;
            this.expireDate = null;
            this.showForm = true;

            if (this.state !== null) {
                if (this.state.duration !== null) {
                    this.expireDate = new Date(Date.now() + this.state.duration * 1000);
                    this.expireDate.setMinutes(Math.round(this.expireDate.getMinutes() / 30) * 30);
                    this.expireDate.setSeconds(0);
                }
            }

            this.$nextTick(() => {
                if (this.$refs.formItems) {
                    this.$refs.formItems.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
            });
        },

        openItems() {
            if (this.process && this.process.items) {
                this.values = this.process.items.map(fromItem);
            } else {
                this.values = [];
            }
        },

        close() {
            this.state = null;
            this.showForm = false;
            this.errorMsg = null;
        },

        submitUploadFile(value, file) {
            const data = new FormData();
            data.append('file', file);
            Api.uploadFile(data).then((resp) => {
                value.files.push(resp.data);
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },

        submitRemoveFile(fileID) {
            Api.removeFile(fileID).then(() => {
                for (let i = 0; i < this.values.length; i += 1) {
                    const v = this.values[i];
                    if (v.files) {
                        v.files = v.files.filter(f => f.id !== fileID);
                    }
                }
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },

        cancel() {
            this.close();
            this.$emit('reset');
        },

        complete() {
            for (let i = 0; i < this.values.length; i += 1) {
                if (!validateValue(this.values[i])) {
                    this.errorMsg = 'Не все обязательные поля заполнены';
                    return;
                }
            }

            if (this.state == null) {
                this.errorMsg = 'Требуется выбрать действие';
                return;
            }

            const payload = {
                values: this.values.map(toPayload).filter(v => v != null),
            };

            if (this.state.duration != null) {
                payload.expire = this.expireDate.getTime();
            }

            // Добавляем назначенных пользователей
            if (this.showUsers) {
                const executors = this.$refs.users.users;

                if (this.state.type === 'assigned' && executors.length < 1) {
                    this.errorMsg = 'Требуется назначить хотя бы одного исполнителя';
                    return;
                }
                if (this.state.type === 'parallel' && executors.length !== this.state.parallel.count) {
                    this.errorMsg = `Требуется назначить ровно ${this.state.parallel.count} исполнителей`;
                    return;
                }

                payload.accounts = executors;
            }

            // Добавляем назначенные разделы
            if (this.showScopes) {
                payload.subform = this.$refs.scopes.startFormID;

                if (this.state.subprocess.type !== 'all') {
                    payload.scopes = this.$refs.scopes.scopes;
                }
            }

            this.$emit('complete', payload);
        },
    },
};
