export * from '@/store/pagedlist/actions';

export const FILTER_SCHEMA = 'FILTER_SCHEMA';
export const FILTER_STATE = 'FILTER_STATE';
export const FILTER_SEARCH = 'FILTER_SEARCH';
export const FILTER_ORDER = 'FILTER_ORDER';

export const START_NEW_TASK = 'START_NEW_TASK';
export const SELECT_TASK = 'SELECT_TASK';
export const COMPLETE_TASK = 'COMPLETE_TASK';
