<template>
    <div class="file_upload">
        <div class="input text-center">
            <input class="file"
                :id="'file_' + id" ref="file" type="file" :accept="accept"
                @change="change($event.target.files[0])" />
            <label :for="'file_' + id">
                <strong><slot /></strong>
                <!-- <span class="dragndrop"> или перетащите его сюда</span>. -->
            </label>
        </div>
    </div>
</template>

<style lang="scss" scoped>

.file_upload {
    font-size: 1rem;
    // background-color: #c8dadf;
    position: relative;
    padding: 20px 20px;

    outline: 1px dashed black;
    outline-offset: -10px;

    .file {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;

        & + label {
            max-width: 80%;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            display: inline-block;
            overflow: hidden;
        }

    }
}
</style>

<script>
export default {
    name: 'FileUpload',
    props: ['accept'],
    data() {
        return {
            id: null,
            fileData: null,
        };
    },
    mounted() {
        // eslint-disable-next-line no-underscore-dangle
        this.id = this._uid;
    },
    methods: {
        change(file) {
            this.$emit('upload-file', file);
            this.$refs.file.value = '';
        },
    },
};
</script>
