import Vue from 'vue';
import Router from 'vue-router';

// eslint-disable-next-line no-unused-vars
import WorkInProgress from '@/components/common/WorkInProgress';
// eslint-disable-next-line no-unused-vars
import Common from '@/components/common/common';

import AppDefault from '@/components/main/AppDefault';
import AppCompleteAuth from '@/components/main/AppCompleteAuth';
import AppAutoAuth from '@/components/main/AppAutoAuth';

// Lazy loading
const AppCard = () => import(/* webpackChunkName: "user" */ '@/components/scope/AppCard.vue');
const AppConfig = () => import(/* webpackChunkName: "user" */ '@/components/scope/AppScopeConfig.vue');
const NewProcess = () => import(/* webpackChunkName: "user" */ '@/components/scope/NewProcess.vue');
const NewScope = () => import(/* webpackChunkName: "user" */ '@/components/scope/NewScope.vue');
const UserProfile = () => import(/* webpackChunkName: "user" */ '@/components/user/UserProfile.vue');
const TaskValue = () => import(/* webpackChunkName: "user" */ '@/components/taskvalue/TaskValue.vue');

const TaskList = () => import(/* webpackChunkName: "user" */ '@/components/tasks/TaskList.vue');
const TaskInfo = () => import(/* webpackChunkName: "user" */ '@/components/tasks/TaskInfo.vue');
const ReportsList = () => import(/* webpackChunkName: "user" */ '@/components/reports/ReportsList.vue');
const ReportInfo = () => import(/* webpackChunkName: "user" */ '@/components/reports/ReportInfo.vue');
const ProcessesList = () => import(/* webpackChunkName: "user" */ '@/components/processes/ProcessesList.vue');
const ProcessInfo = () => import(/* webpackChunkName: "user" */ '@/components/processes/ProcessInfo.vue');
const AppReportsList = () => import(/* webpackChunkName: "user" */ '@/components/scope/reports/AppReportsList.vue');
const AppReportInfo = () => import(/* webpackChunkName: "user" */ '@/components/scope/reports/AppReportInfo.vue');
const AppRegistriesList = () => import(/* webpackChunkName: "user" */ '@/components/scope/registries/RegistriesList.vue');
const AppRegistryRows = () => import(/* webpackChunkName: "user" */ '@/components/scope/registries/RegistryRows.vue');
const CreateAppRegistryRow = () => import(/* webpackChunkName: "user" */ '@/components/scope/registries/CreateRegistryRow.vue');
const RegistryRowInfo = () => import(/* webpackChunkName: "user" */ '@/components/scope/registries/RegistryRowInfo.vue');

const AppTypesList = () => import(/* webpackChunkName: "admin" */ '@/components/scope/types/AppTypesList.vue');
const AppTypeInfo = () => import(/* webpackChunkName: "admin" */ '@/components/scope/types/AppTypeInfo.vue');
const NewAppType = () => import(/* webpackChunkName: "admin" */ '@/components/scope/types/NewAppType.vue');
const NewMetaType = () => import(/* webpackChunkName: "admin" */ '@/components/scope/types/NewMetaType.vue');
const CreateNewRegistry = () => import(/* webpackChunkName: "admin" */ '@/components/scope/types/registries/CreateNewRegistry.vue');
const RegistryInfo = () => import(/* webpackChunkName: "admin" */ '@/components/scope/types/registries/RegistryInfo.vue');

const AppTasks = () => import(/* webpackChunkName: "admin" */ '@/components/scope/tasks/AppTaskList.vue');
const AppUsers = () => import(/* webpackChunkName: "admin" */ '@/components/scope/users/AccountsList.vue');
const AppNewUser = () => import(/* webpackChunkName: "admin" */ '@/components/scope/users/CreateUser.vue');
const AppNewExtUser = () => import(/* webpackChunkName: "admin" */ '@/components/scope/users/CreateExtUser.vue');
const AppUserInfo = () => import(/* webpackChunkName: "admin" */ '@/components/scope/users/AppUserInfo.vue');
const AppProcessesList = () => import(/* webpackChunkName: "admin" */ '@/components/scope/processes/AppProcessesList.vue');
const AppSchemasList = () => import(/* webpackChunkName: "admin" */ '@/components/scope/schemas/ProcessSchemasList.vue');
const AppSchemaInfo = () => import(/* webpackChunkName: "admin" */ '@/components/scope/schemas/ProcessSchemaInfo.vue');
const ProcessAdminInfo = () => import(/* webpackChunkName: "admin" */ '@/components/mprocess/ProcessAdminInfo.vue');

Vue.use(Router);

/* eslint-disable object-curly-newline */
export default new Router({
    mode: 'history',
    routes: [
        // Дефотный адрес
        { path: '/', name: 'default', component: AppDefault },
        { path: '/auto-auth', name: 'auto-auth', component: AppAutoAuth, meta: { withoutAuth: true } },
        { path: '/complete-auth', name: 'complete-auth', component: AppCompleteAuth, meta: { withoutAuth: true } },

        // Отдельный роут для профиля пользователя
        { path: '/profile', name: 'profile', component: UserProfile },

        // Специальный роут для просмотра файлов задач по иденфикатору.
        // Этот роут используется во внешних отчетах
        { path: '/tfiles/:tvID', name: 'taskvalue-files', component: TaskValue, props: true },

        { path: '/tasks', name: 'tasks', component: TaskList },
        { path: '/task/:taskID', name: 'task-info', component: TaskInfo, props: true },
        { path: '/reports', name: 'reports', component: ReportsList },
        { path: '/report/:reportID', name: 'report-info', component: ReportInfo, props: true },
        { path: '/processes', name: 'processes', component: ProcessesList },
        { path: '/process/:processID', name: 'process-info', component: ProcessInfo, props: true },
        { path: '/mprocess/:processID', name: 'process-admin-info', component: ProcessAdminInfo, props: true },

        { path: '/app/:appID', name: 'app-card', component: AppCard, props: true },
        { path: '/app/:appID/new-process', name: 'app-new-process', component: NewProcess, props: true },
        { path: '/app/:appID/new-user', name: 'app-new-user', component: AppNewUser, props: true },
        { path: '/app/:appID/new-extuser', name: 'app-new-extuser', component: AppNewExtUser, props: true },
        { path: '/app/:appID/new-app', name: 'app-new-scope', component: NewScope, props: true },
        { path: '/app/:appID/new-type', name: 'app-new-type', component: NewAppType, props: true },
        { path: '/app/:appID/type/:typeID/new-type', name: 'app-new-metatype', component: NewMetaType, props: true },
        { path: '/app/:appID/config', name: 'app-config', component: AppConfig, props: true },

        { path: '/app/:appID/tasks', name: 'app-tasks', component: AppTasks, props: true },
        { path: '/app/:appID/users', name: 'app-users', component: AppUsers, props: true },
        { path: '/app/:appID/user/:userID', name: 'app-user-info', component: AppUserInfo, props: true },
        { path: '/app/:appID/types', name: 'app-types', component: AppTypesList, props: true },
        { path: '/app/:appID/type/:typeID', name: 'app-type-info', component: AppTypeInfo, props: true },
        { path: '/app/:appID/type/:typeID/create-registry', name: 'create-registry', component: CreateNewRegistry, props: true },
        { path: '/app/:appID/type/:typeID/registry/:registryID', name: 'registry-info', component: RegistryInfo, props: true },

        { path: '/app/:appID/schemas', name: 'app-schemas', component: AppSchemasList, props: true },
        { path: '/app/:appID/schemas/:schemaID', name: 'app-schema-info', component: AppSchemaInfo, props: true },
        { path: '/app/:appID/processes', name: 'app-processes', component: AppProcessesList, props: true },
        { path: '/app/:appID/reports', name: 'app-reports', component: AppReportsList, props: true },
        { path: '/app/:appID/report/:reportID', name: 'app-report-info', component: AppReportInfo, props: true },

        { path: '/app/:appID/registries', name: 'app-registries', component: AppRegistriesList, props: true },
        { path: '/app/:appID/registry/:registryID', name: 'app-registry-rows', component: AppRegistryRows, props: true },
        { path: '/app/:appID/registry/:registryID/new', name: 'app-registry-new-row', component: CreateAppRegistryRow, props: true },
        { path: '/app/:appID/regrow/:rowID', name: 'app-registry-row', component: RegistryRowInfo, props: true },

        { path: '/*', redirect: '/' },
    ],
});
