import _get from 'lodash/get';
import Api from '@/api';
import PagedList from '@/store/pagedlist';

import * as A from './actions';
import * as M from './mutations';

const page = new PagedList('pages');

const initState = {
    appID: null,
    registry: null,
    list: [],
    error: null,
    pages: page.initState(),
    filters: {},
    available: {},
    row: null,
};

const getters = { };

const mutations = {
    [M.LOAD](state, {
        appID, registry, list, pagination, filters, available,
    }) {
        state.appID = appID;
        state.registry = registry;
        state.list = list;
        state.pages = page.readState(pagination);
        state.error = null;
        state.filters = filters;
        state.available = available;
    },
    [M.SELECT](state, { appID, row }) {
        state.row = row;
        if (state.appID !== appID
            || !state.registry
            || state.registry.id !== row.registry.id) {
            state.appID = appID;
            state.registry = row.registry;
            state.list = [];
            state.pages = page.initState();
            state.error = null;
            state.filters = {};
            state.available = {};
        }
    },
    [M.RESET](state) {
        state.appID = null;
        state.registry = null;
        state.list = [];
        state.pages = page.initState();
        state.error = null;
        state.filters = {};
        state.available = {};
        state.row = null;
    },
    [M.ERROR](state, error) {
        state.error = error;
    },
};

const actions = {
    ...page.mapActions(),

    [A.UPDATE_LIST]({ dispatch, state }, { appID, registryID }) {
        const changed = appID !== state.appID
            || !state.registry || registryID !== state.registry.id;
        return (page.obsolete(state) || changed)
            ? dispatch(A.LOAD_LIST, { appID, registryID }) : Promise.resolve();
    },

    [A.LOAD_LIST]({ commit, dispatch, state }, opts) {
        const appID = _get(opts, 'appID', state.appID);
        const registryID = _get(opts, 'registryID', state.registry && state.registry.id);
        const pageOpts = page.pageOpts(state, opts);

        return new Promise((resolve, reject) => {
            Api.scopeRegistryRows(appID, registryID, pageOpts).then((resp) => {
                commit(M.LOAD, { appID, ...resp.data });
                dispatch('profile/UPDATE_NAV', { appID }, { root: true });
                resolve(resp.data);
            }).catch((err) => {
                commit(M.ERROR, err);
                reject(err);
            });
        });
    },

    [A.FILTER_SEARCH]({ dispatch }, value) {
        dispatch(value ? A.SET_FILTER : A.CLEAR_FILTER, { filter: 'search', value });
    },

    [A.SELECT_ROW]({ commit, dispatch }, { appID, rowID }) {
        return new Promise((resolve, reject) => {
            Api.fetchRegistryRow(appID, rowID).then((resp) => {
                dispatch('profile/UPDATE_NAV', { appID }, { root: true });
                commit(M.SELECT, { appID, row: resp.data });
                resolve();
            }).catch(reject);
        });
    },

    [A.CREATE_ROW]({ commit, state, dispatch }, { payload }) {
        const { appID, registry } = state;
        return new Promise((resolve, reject) => {
            Api.createRegistryRow(appID, registry.id, payload).then(() => {
                dispatch(A.LOAD_LIST);
                resolve();
            }).catch((err) => {
                commit(M.ERROR, err);
                reject(err);
            });
        });
    },

    [A.UPDATE_ROW]({ commit, dispatch }, { appID, rowID, payload }) {
        return new Promise((resolve, reject) => {
            Api.updateRegistryRow(appID, rowID, payload).then((resp) => {
                commit(M.SELECT, { appID, row: resp.data });
                dispatch(A.LOAD_LIST);
                resolve();
            }).catch((err) => {
                commit(M.ERROR, err);
                reject(err);
            });
        });
    },

    [A.REMOVE_ROW]({ commit, dispatch }, { appID, rowID }) {
        return new Promise((resolve, reject) => {
            Api.removeRegistryRow(appID, rowID).then(() => {
                dispatch(A.LOAD_LIST);
                resolve();
            }).catch((err) => {
                commit(M.ERROR, err);
                reject(err);
            });
        });
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
