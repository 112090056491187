<template>
    <div id="app">
        <app-header></app-header>
        <router-view v-if="withoutAuth" />
        <app-body v-else />
    </div>
</template>

<style lang="scss">

@import "~spectre.css/src/_variables";

body {
    margin: 0;
    background-color: $gray-color-light;
}

#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

pre {
    white-space: pre-wrap;
}

.c-hover-hand {
    cursor: pointer;
    &:hover {
        background-color: #f1f1fc;
    }
}

.app-tile-icon {
    -ms-flex-align: center;
    -ms-flex-line-pack: distribute;

    align-items: center;
    justify-content: center;

    background: $primary-color;
    border-radius: .2rem;
    color: #fff;
    display: flex;
    display: -ms-flexbox;
    font-size: 1.2rem;
    height: 2rem;
    width: 2rem;
}
</style>

<script>

import 'spectre.css/dist/spectre.css';
import 'spectre.css/dist/spectre-icons.css';
import 'spectre.css/dist/spectre-exp.css';

import AppHeader from '@/components/main/AppHeader';
import AppBody from '@/components/main/AppBody';

export default {
    name: 'App',
    components: { AppHeader, AppBody },
    computed: {
        withoutAuth() {
            return this.$route.meta.withoutAuth || false;
        },
    },
};

</script>
