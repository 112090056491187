<template>
    <div class="login-container">
        <h2>Вход в систему</h2>
        <form v-on:submit.prevent="submitLogin" :class="{ 'has-error' : app.errorMsg != null }">
            <div class="form-group controls">
                <p class="form-input-hint">{{app.errorMsg}}</p>
            </div>
            <div class="form-group" >
                <label class="form-label">Имя пользователя</label>
                <input class="form-input" type="text" v-model="username" />
                <label class="input-label">Пароль</label>
                <input class="form-input" type="password" v-model="password" />
            </div>
            <div class="form-group controls">
                <button class="btn btn-primary" type="submit">Вход</button>
                <div class="text-right">
                    <div v-for="ext in app.authList" :key="ext.code">
                        <a class="btn btn-link"
                            :href="ext.url"
                            >Войти через {{ext.name}}</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>

.login-container {
    position: fixed;
    width: 300px;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    form {
        text-align: left;
    }

    .controls {
        text-align: center;
    }
}

</style>

<script>

import { mapState, mapActions } from 'vuex';
import { LOGIN } from '@/store/actions';

export default {
    name: 'LoginComponent',
    data() {
        return {
            username: '',
            password: '',
        };
    },
    computed: {
        ...mapState(['app']),
    },

    methods: {
        ...mapActions({
            login: LOGIN,
        }),

        submitLogin() {
            this.login({
                username: this.username,
                password: this.password,
            });
        },
    },
};

</script>
