import firebase from 'firebase/app';
import 'firebase/messaging';

function onMessage(payload) {
    const options = {
        title: payload.notification.title,
        body: payload.notification.body,
        data: {},
    };

    // Для обработки клика - формируем урл
    if (payload.data && payload.data.taskID) {
        options.icon = '/new_task.png';
        options.data.url = `/task/${Number(payload.data.taskID)}`;
        // Обязательно указываем тег для исключения дублирования уведомлений
        options.tag = `task${Number(payload.data.taskID)}`;
    }

    navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope')
        .then(registration => registration.showNotification(payload.notification.title, options));
}

let app = null;


const Proxy = {
    init(config, callback) {
        this.destroy();
        app = firebase.initializeApp(config, 'admin-web');

        const messaging = firebase.messaging(app);

        // Вешаем обработчик для получения сообщений
        messaging.onMessage(callback !== undefined ? callback : onMessage);

        return messaging.requestPermission()
            .then(() => messaging.getToken())
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log('Unable to get permission to notify.', err.message);
            });
    },

    destroy() {
        if (app) {
            app.delete();
            app = null;
        }
    },
};

export default Proxy;
