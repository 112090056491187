<template>
    <div class="empty">
        <div class="empty-icon">
            <i class="icon icon-4x icon-stop"></i>
        </div>
        <p class="empty-title h4"><slot></slot></p>
    </div>
</template>

<script>
export default {
    name: 'AppNotFound',
};
</script>
