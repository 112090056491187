import { render, staticRenderFns } from "./AppAttrList.vue?vue&type=template&id=10fabddf&scoped=true&"
import script from "./AppAttrList.js?vue&type=script&lang=js&"
export * from "./AppAttrList.js?vue&type=script&lang=js&"
import style0 from "./AppAttrList.vue?vue&type=style&index=0&id=10fabddf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10fabddf",
  null
  
)

export default component.exports