import Vue from 'vue';
import Vuex from 'vuex';
import Api from '@/api';
import Firebase from '@/firebase';

import profile from './profile';
import scope from './scope';
import tasks from './tasks';
import reports from './reports';
import processes from './processes';
import taskvalue from './taskvalue';
import mprocess from './mprocess';

import * as A from './actions';
import * as M from './mutations';

Vue.use(Vuex);

function resetModules(commit) {
    commit('mprocess/RESET');
    commit('tasks/RESET');
    commit('reports/RESET');
    commit('processes/RESET');
    commit('taskvalue/RESET');
    commit('scope/RESET');
    commit('scope/scopes/RESET');
    commit('scope/types/RESET');
    commit('scope/types/registries/RESET');
    commit('scope/users/RESET');
    commit('scope/schemas/RESET');
    commit('scope/processes/RESET');
    commit('scope/registries/RESET');
    commit('scope/registryrows/RESET');
}

function login(commit, dispatch, userinfo) {
    commit(M.INIT, userinfo);
    dispatch('profile/INIT_PROFILE', userinfo);
    resetModules(commit);

    if (userinfo.firebase) {
        Firebase.init(userinfo.firebase, (payload) => {
            commit(M.ADD_MSG, payload);
        }).then((token) => {
            Api.updateDeviceID(token);
        });
    }
}

function logout(commit) {
    Firebase.destroy();
    commit(M.RESET);
    commit('profile/RESET');
    resetModules(commit);
}

const Store = new Vuex.Store({
    // На дев версии - жестко проверяем работу стора.
    // На проде strict отключаем, так как он потребляет много ресурсов
    strict: process.env.NODE !== 'production',

    state: {
        app: {
            userID: undefined,
            authList: [],
            messagesList: [],
            messagesCounter: 0,
            errorMsg: null,
        },
    },

    getters: {
        isLogged: state => state.app.userID !== null && state.app.userID !== undefined,
        isNotLogged: state => state.app.userID === null,

        appUserID: state => state.app.userID,
        appError: state => state.app.errorMsg,
    },

    mutations: {
        [M.INIT](state, info) {
            state.app.userID = info.id;
            state.app.errorMsg = null;
            state.app.messagesList = [];
            state.app.messagesCounter = 0;
        },
        [M.RESET](state) {
            state.app.userID = null;
            state.app.errorMsg = null;
            state.app.messagesList = [];
            state.app.messagesCounter = 0;
        },
        [M.SHOW_ERROR](state, errorMsg) {
            state.app.errorMsg = errorMsg;
        },
        [M.UPDATE_EXT_LIST](state, list) {
            state.app.authList = list;
        },
        [M.REMOVE_MSG](state, msg) {
            const i = state.app.messagesList.indexOf(msg);
            if (i >= 0) {
                state.app.messagesList.splice(i, 1);
            }
        },
        [M.ADD_MSG](state, payload) {
            state.app.messagesCounter += 1;
            state.app.messagesList.unshift({
                id: state.app.messagesCounter,
                title: payload.notification.title,
                body: payload.notification.body,
                taskID: payload.data.taskID,
            });
        },
    },

    actions: {
        [A.INIT]({ commit, dispatch }) {
            Api.profile().then((resp) => {
                login(commit, dispatch, resp.data);
                dispatch(A.LIST_AUTHS);
            }).catch((e) => {
                dispatch(A.LIST_AUTHS).finally(() => {
                    logout(commit);
                    if (!e.response || e.response.status !== 401) {
                        commit(M.SHOW_ERROR, e.message);
                    }
                });
            });
        },

        [A.LOGIN]({ commit, dispatch }, { username, password }) {
            Api.loginPassword(username, password).then((resp) => {
                login(commit, dispatch, resp.data);
            }).catch((err) => {
                if (err.response.status === 401) {
                    commit(M.SHOW_ERROR, 'Неверный логин или пароль');
                } else {
                    commit(M.SHOW_ERROR, 'Произошла неизвестная ошибка');
                }
            });
        },

        [A.LOGOUT]({ commit }) {
            Api.logout().finally(() => {
                logout(commit);
            });
        },

        [A.REMOVE_MSG]({ commit }, msg) {
            commit(M.REMOVE_MSG, msg);
        },

        [A.LIST_AUTHS]({ commit }) {
            return new Promise((resolve, reject) => {
                Api.listAuths().then((resp) => {
                    commit(M.UPDATE_EXT_LIST, resp.data);
                    resolve();
                }).catch(reject);
            });
        },

        [A.COMPLETE_AUTH]({ commit, dispatch }, { auth, code }) {
            return new Promise((resolve, reject) => {
                Api.listAuths().then((resp) => {
                    commit(M.UPDATE_EXT_LIST, resp.data);
                    if (!resp.data.find(e => e.code === auth)) {
                        commit(M.SHOW_ERROR, 'Сервер аутенфикации не доступен');
                        reject();
                        return;
                    }

                    Api.completeAuth(auth, { code }).then((resp2) => {
                        login(commit, dispatch, resp2.data);
                        resolve();
                    }).catch((err) => {
                        if (err.response.status === 401) {
                            commit(M.SHOW_ERROR, 'Неверный логин или пароль');
                        } else if (err.response.status === 404) {
                            commit(M.SHOW_ERROR, 'Данный аккаунт не имеет доступа к системе');
                        } else {
                            commit(M.SHOW_ERROR, 'Произошла неизвестная ошибка');
                        }
                        reject();
                    });
                }).catch((err) => {
                    commit(M.SHOW_ERROR, 'Сервер аутенфикации не доступен');
                    reject(err);
                });
            });
        },
    },

    modules: {
        profile,
        scope,
        tasks,
        reports,
        processes,
        taskvalue,
        mprocess,
    },
});

// Устанавливаем на Api фильтр аутенфикации
Api.initAuthInteceptor(() => Store.commit(M.RESET));

export default Store;
