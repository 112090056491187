import Api from '@/api';

import * as A from './actions';
import * as M from './mutations';

import registries from './registries';

const OBSOLETE_TIMEOUT = 60 * 1000; // 60 Секунд для таймаута обновления

const initState = {
    scopeID: null,
    types: [],
    lastTouch: 0,
    selected: null,
};

const getters = { };

const mutations = {
    [M.LOAD_LIST](state, { id, list }) {
        state.scopeID = id;
        state.types = list;
        state.lastTouch = new Date().getTime();
        state.selected = null;
    },

    [M.SELECT_TYPE](state, selected) {
        state.selected = selected;
    },

    [M.RESET](state) {
        state.scopeID = null;
        state.types = [];
        state.lastTouch = 0;
        state.selected = null;
    },
};

const actions = {
    [A.UPDATE_LIST]({ commit, dispatch, state }, scopeID) {
        return new Promise((resolve, reject) => {
            // Если данный тип уже загружен, и не устарел - то ничего не делаем
            if (state.scopeID === scopeID
                && (new Date().getTime() - state.lastTouch < OBSOLETE_TIMEOUT)) {
                resolve();
            } else {
                Api.scopeTypes(scopeID).then((resp) => {
                    dispatch('profile/UPDATE_NAV', { appID: scopeID }, { root: true });
                    commit(M.LOAD_LIST, { id: scopeID, list: resp.data });
                    resolve();
                }).catch((err) => {
                    commit(M.RESET);
                    reject(err);
                });
            }
        });
    },
    [A.CREATE_TYPE]({ commit }, { appID, payload }) {
        return new Promise((resolve, reject) => {
            Api.createScopeType(appID, payload).then(() => {
                Api.scopeTypes(appID).then((resp) => {
                    commit(M.LOAD_LIST, { id: appID, list: resp.data });
                    resolve();
                }).catch(reject);
            }).catch(reject);
        });
    },
    // eslint-disable-next-line no-empty-pattern
    [A.CREATE_METATYPE]({/* */}, { typeID, payload }) {
        return Api.createMetaType(typeID, payload);
    },
    [A.UPDATE_TYPE]({ commit }, { typeID, payload }) {
        return new Promise((resolve, reject) => {
            Api.updateType(typeID, payload).then((resp) => {
                commit(M.SELECT_TYPE, resp.data);
                resolve();
            }).catch(reject);
        });
    },
    [A.REMOVE_TYPE]({ commit }, { appID, id }) {
        return new Promise((resolve, reject) => {
            Api.removeType(id).then(() => {
                Api.scopeTypes(appID).then((resp) => {
                    commit(M.LOAD_LIST, { id: appID, list: resp.data });
                    resolve();
                }).catch(reject);
            }).catch(reject);
        });
    },
    [A.SELECT_TYPE]({ commit }, typeID) {
        return new Promise((resolve, reject) => {
            Api.type(typeID).then((resp) => {
                commit(M.SELECT_TYPE, resp.data);
                resolve();
            }).catch(reject);
        });
    },
    [A.SELECT_SCOPETYPE]({ commit }, { scopeID, typeID }) {
        return new Promise((resolve, reject) => {
            Api.scopeType(scopeID, typeID).then((resp) => {
                commit(M.SELECT_TYPE, resp.data);
                resolve();
            }).catch(reject);
        });
    },
    [A.CREATE_ROLE]({ commit }, { typeID, payload }) {
        return new Promise((resolve, reject) => {
            Api.createRole(typeID, payload).then(() => {
                Api.type(typeID).then((resp) => {
                    commit(M.SELECT_TYPE, resp.data);
                    resolve();
                }).catch(reject);
            }).catch(reject);
        });
    },
    [A.UPDATE_ROLE]({ commit }, { typeID, id, payload }) {
        return new Promise((resolve, reject) => {
            Api.updateRole(id, payload).then(() => {
                Api.type(typeID).then((resp) => {
                    commit(M.SELECT_TYPE, resp.data);
                    resolve();
                }).catch(reject);
            }).catch(reject);
        });
    },
    [A.REMOVE_ROLE]({ commit }, payload) {
        const { typeID, id } = payload;
        return new Promise((resolve, reject) => {
            Api.removeRole(id).then(() => {
                Api.type(typeID).then((resp) => {
                    commit(M.SELECT_TYPE, resp.data);
                    resolve();
                }).catch(reject);
            }).catch(reject);
        });
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
    modules: {
        registries,
    },
};
