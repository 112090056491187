import Api from '@/api';

import * as A from './actions';

const LOAD = 'LOAD';
const RESET = 'RESET';

const init = {
    taskvalue: null,
};

const getters = {
};

const mutations = {
    [LOAD](state, value) {
        state.taskvalue = value;
    },
    [RESET](state) {
        state.taskvalue = null;
    },
};

const actions = {
    [A.LOAD_VALUE]({ commit }, id) {
        return new Promise((resolve, reject) => {
            Api.fetchTaskValue(id).then((resp) => {
                commit(LOAD, resp.data);
                resolve();
            }).catch(reject);
        });
    },
};

export default {
    namespaced: true,
    state: init,
    getters,
    actions,
    mutations,
};
