export const UPDATE_LIST = 'UPDATE_LIST';

export const CREATE_TYPE = 'CREATE_TYPE';
export const CREATE_METATYPE = 'CREATE_METATYPE';
export const REMOVE_TYPE = 'REMOVE_TYPE';
export const UPDATE_TYPE = 'UPDATE_TYPE';

export const SELECT_TYPE = 'SELECT_TYPE';
export const SELECT_SCOPETYPE = 'SELECT_SCOPETYPE';

export const CREATE_ROLE = 'CREATE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const REMOVE_ROLE = 'REMOVE_ROLE';
