export * from '@/store/pagedlist/actions';

export const SELECT_PROCESS = 'SELECT_PROCESS';
export const UPDATE_PROCESS = 'UPDATE_PROCESS';
export const REMOVE_PROCESS = 'REMOVE_PROCESS';
export const PATCH_PROCESS = 'PATCH_PROCESS';
export const REVERT_PROCESS = 'REVERT_PROCESS';

export const FILTER_TREE = 'FILTER_TREE';
export const FILTER_FINISHED = 'FILTER_FINISHED';
export const FILTER_SCHEMA = 'FILTER_SCHEMA';
export const FILTER_STATE = 'FILTER_STATE';
export const FILTER_SEARCH = 'FILTER_SEARCH';
