import _get from 'lodash/get';

import TablePagination from '@/components/common/TablePagination';
import SearchBox from '@/components/common/SearchBox';
import TaskScopeRef from './TaskScopeRef';

export default {
    name: 'TaskScopes',
    components: { TablePagination, TaskScopeRef, SearchBox },

    props: {
        listScopes: {
            type: Function,
        },
        type: {
            type: String,
        },
        schemaName: {
            type: String,
        },
        schemaForms: {
            type: Array,
        },
        scopesAvailabled: {
            type: Number,
        },
    },

    data() {
        return {
            isLoading: false,
            errorMsg: null,

            searchText: null,
            requestCount: 0,
            list: [],
            pages: {
                page: 0,
                size: 10,
                count: 0,
            },
            lastTouch: 0,

            selected: [],
            formID: null,
        };
    },

    computed: {
        isOne() { return this.type === 'one'; },
        isAll() { return this.type === 'all'; },

        count() { return this.selected.length; },
        scopes() { return this.selected.map(s => s.id); },
        startFormID() { return this.formID; },
    },

    created() {
        this.reload();
        this.formID = this.schemaForms ? this.schemaForms[0].id : -1;
    },

    methods: {
        clear() {
            this.list = [];
            this.searchText = null;
            this.requestCount = 0;
            this.isLoading = false;
            this.pages.page = 0;
            this.pages.size = 10;
            this.pages.count = 0;
            this.lastTouch = 0;
            this.errorMsg = null;
            this.selected = [];
        },

        reload(opts) {
            if (this.isAll) {
                return;
            }

            const size = _get(opts, 'size', this.pages.size);
            const offset = _get(opts, 'page', this.pages.current) * size;
            const search = this.searchText || null;

            this.requestCount += 1;

            this.listScopes({ offset, size, filters: { search } }).then((resp) => {
                const { list, pagination, filters } = resp.data;
                this.list = list;
                this.searchText = (filters && filters.search) || null;
                this.pages.page = Math.floor(pagination.offset / pagination.pagesize);
                this.pages.size = pagination.pagesize;
                this.pages.count = Math.ceil(pagination.total / pagination.pagesize) - 1;
                this.lastTouch = new Date().getTime();
                this.errorMsg = null;
                this.isLoading = false;
                if (this.requestCount > 0) {
                    this.requestCount -= 1;
                }
            }).catch((err) => {
                this.clear();
                this.errorMsg = err.message;
                if (this.requestCount > 0) {
                    this.requestCount -= 1;
                }
            });
        },

        changePage(page) {
            this.reload({ page: page || 0 });
        },

        switchScope(scope) {
            for (let idx = 0; idx < this.selected.length; idx += 1) {
                if (this.selected[idx].id === scope.id) {
                    this.selected.splice(idx, 1);
                    return;
                }
            }
            this.selected.push(scope);
        },

        hasScope(scope) {
            for (let idx = 0; idx < this.selected.length; idx += 1) {
                if (this.selected[idx].id === scope.id) {
                    return true;
                }
            }
            return false;
        },
    },
};
